import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import { useLazyGetNotesQuery } from '../../api/notes';
import {
    useGetCollectionQuery,
    useGetCollectionTeamsQuery,
} from '../../api/collections';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';
import { InputSwitch } from 'primereact/inputswitch';

import { useLazyGetTeamSeasonSummaryReportQuery } from '../../api/reports';
import { useLazyGetTeamParticipatingSeasonsQuery } from '../../api/seasons';

import { Season } from '../../types/seasons';
import { Player, Team } from '../../types/team';
import { orderBy } from 'lodash';
import { useLazyGetPlayersQuery } from '../../api/players';
import { useLazyGetSeasonEventsQuery } from '../../api/events';
import { Event } from '../../types/event';
import { BaseEntityType } from '../../types/common';
import { toISOStringWithTimezone, getEntityFromParam } from '../../util/helper';
import { format, isWithinInterval, subDays } from 'date-fns';
import Icon from '../../components/Icon';
import { Note } from '../../types/note';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface ReportData {
    [key: string]: {
        isLoading: boolean;
        isError: boolean;
        isInitialized: boolean;
        reports?: any[];
    };
}

// This must become dynamic once you are required to have a licence at Org level
const licenceLevel = 'Advanced';

interface ReportState {
    [key: string]: {
        team: Team;
        seasons: ReportData;
    };
}

const AssocCompetitionPGTReport = () => {
    const location = useLocation();
    const params = useParams();
    const [localCollectionID, setLocalCollectionID] = useState<string | null>(
        null
    );
    const [teams, setTeams] = useState<Team[]>([]); // Specify Team[] type here

    // Update localCollectionID only when collectionID is defined and different from the current
    useEffect(() => {
        const newCollectionID = location.state?.selectedCollection;
        if (newCollectionID && newCollectionID !== localCollectionID) {
            setLocalCollectionID(newCollectionID);
            setTeams([]); // Clear old teams data on collectionID change
        }
    }, [location.state?.selectedCollection, localCollectionID]);

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const { data } = useGetCollectionTeamsQuery(
        // @ts-expect-error
        { ...activeEntity, collectionID: localCollectionID },
        {
            skip: !localCollectionID,
        }
    );

    // Update teams data whenever new query results are available
    useEffect(() => {
        if (data) {
            setTeams(data.data || []); // Ensure this is typed correctly
        }
    }, [data]);

    const { data: collectionData } = useGetCollectionQuery(
        // @ts-expect-error
        { ...activeEntity, collectionID: localCollectionID },
        {
            skip: !activeEntity || !localCollectionID,
        }
    );

    // Route Params
    const { organisationID } = useParams();

    const timestampRef = useRef(Date.now()).current;

    const [teamReports, setTeamReports] = useState<ReportState>({});
    const [categoryValue, setCategoryValue] =
        useState<string>('Minimum Game Time');

    const [dateRange, setDateRange] = useState<string>('week');
    const [playerData, setPlayerData] = useState<Player[]>([]);
    const [eventData, setEventData] = useState<Event[]>([]);
    const [seasonData, setSeasonData] = useState<{ [key: string]: Season[] }>(
        {}
    );
    const [notesData, setNotesData] = useState<{ [key: string]: Note[] }>({});
    const [activeSeason, setActiveSeason] = useState<{ [key: string]: string }>(
        {}
    );
    const [hideEmptyRows, setHideEmptyRows] = useState<boolean>(true);
    const [loading, setLoading] = useState(true);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [teamCount, setTeamCount] = useState(0);

    const [fetchTeamNotes] = useLazyGetNotesQuery();
    const [fetchPlayers] = useLazyGetPlayersQuery();
    const [fetchEvents] = useLazyGetSeasonEventsQuery();
    const [fetchTeamSeasonReport] = useLazyGetTeamSeasonSummaryReportQuery();
    const [fetchTeamParticipatingSeasons] =
        useLazyGetTeamParticipatingSeasonsQuery();

    useEffect(() => {
        const fetchNotes = async () => {
            for (const team of teams) {
                const { teamID } = team;
                const notesResult = await fetchTeamNotes({
                    entityID: teamID,
                    entityType: BaseEntityType.teams,
                    cursor: '',
                });

                const notes = notesResult.data?.data?.notes;

                if (notes) {
                    setNotesData((prev) => ({
                        ...prev,
                        [teamID]: notes,
                    }));
                }

                await delay(500); // Throttle requests
            }
        };

        if (teams && teams.length > 0) {
            fetchNotes();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);
    // Set default season as team as active season
    useEffect(() => {
        for (const team of teams) {
            // Set default active season for each team to team's default season ID
            setActiveSeason((prev) => ({
                ...prev,
                [team.teamID]: team.defaultSeasonID,
            }));

            // Set team to initial report structure
            setTeamReports((prev) => ({
                ...prev,
                [team.teamID]: {
                    seasons: {},
                    team,
                },
            }));
        }
    }, [teams]);

    // Set day range for month option to 30 days
    const isWithinLast30Days = (date: Date): boolean => {
        const today = new Date();
        const thirtyDaysAgo = subDays(today, 30);
        return isWithinInterval(date, { start: thirtyDaysAgo, end: today });
    };

    // Set day range for month option to 7 days
    const isWithinLast7Days = (date: Date): boolean => {
        const today = new Date();
        const sevenDaysAgo = subDays(today, 7);
        return isWithinInterval(date, { start: sevenDaysAgo, end: today });
    };

    // Get report of active season, if not yet fetched already
    useEffect(() => {
        const fetchTeamReports = async () => {
            for (const [teamID, seasonID] of Object.entries(activeSeason)) {
                if (
                    !teamReports[teamID].seasons[seasonID] ||
                    !teamReports[teamID].seasons[seasonID].isInitialized
                ) {
                    setTeamReports((prev) => ({
                        ...prev,
                        [teamID]: {
                            ...prev[teamID],
                            seasons: {
                                ...prev[teamID].seasons,
                                [seasonID]: {
                                    ...prev[teamID].seasons[seasonID],
                                    isInitialized: true,
                                    isLoading: true,
                                    isError: false,
                                },
                            },
                        },
                    }));

                    if (seasonID) {
                        try {
                            const reportsResult = await fetchTeamSeasonReport({
                                teamID,
                                seasonID,
                                sessionID: timestampRef,
                                concatenated: true,
                            });

                            if ('error' in reportsResult) {
                                throw new Error(`Failed to download report`);
                            }

                            const reportUrl =
                                reportsResult?.data?.data.objectURL;

                            if (reportUrl) {
                                const response = await fetch(reportUrl);

                                if (!response.ok) {
                                    throw new Error(
                                        `Failed to fetch data from ${reportUrl}`
                                    );
                                }
                                const data = await response.json();

                                setTeamReports((prev) => ({
                                    ...prev,
                                    [teamID]: {
                                        ...prev[teamID],
                                        seasons: {
                                            ...prev[teamID].seasons,
                                            [seasonID]: {
                                                ...prev[teamID].seasons[
                                                    seasonID
                                                ],
                                                reports: data,
                                                isLoading: false,
                                            },
                                        },
                                    },
                                }));

                                await delay(500); // Throttle requests
                            }
                        } catch (error) {
                            setTeamReports((prev) => ({
                                ...prev,
                                [teamID]: {
                                    ...prev[teamID],
                                    seasons: {
                                        ...prev[teamID].seasons,
                                        [seasonID]: {
                                            ...prev[teamID].seasons[seasonID],
                                            isLoading: false,
                                            isError: true,
                                        },
                                    },
                                },
                            }));
                        }

                        // Increment team count
                        setTeamCount((prevCount) => prevCount + 1);
                    }
                }
            }
        };

        fetchTeamReports();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSeason]);

    // Get all participating seasons for each teams
    useEffect(() => {
        const fetchTeamSeasons = async () => {
            for (const team of teams) {
                const { teamID } = team;
                const seasonsResult = await fetchTeamParticipatingSeasons({
                    teamID,
                    cursor: '',
                });

                const seasons = seasonsResult.data?.data;

                if (seasons) {
                    setSeasonData((prev) => ({
                        ...prev,
                        [teamID]: seasons,
                    }));
                }

                await delay(500); // Throttle requests
            }
        };

        if (teams && teams.length > 0) {
            fetchTeamSeasons();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    // Get all players for each team
    useEffect(() => {
        const fetchTeamPlayers = async () => {
            for (const team of teams) {
                const { teamID } = team;
                const playersResults = await fetchPlayers({
                    teamID,
                    cursor: '',
                });

                const players = playersResults.data?.data;

                if (players) {
                    setPlayerData((prev) => [...prev, ...players]);
                }

                await delay(500); // Throttle requests
            }
        };

        if (teams && teams.length > 0) {
            fetchTeamPlayers();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    useEffect(() => {
        const fetchTeamEvents = async () => {
            for (const team of teams) {
                const { teamID } = team;

                if (activeSeason[teamID]) {
                    const eventsResults = await fetchEvents({
                        entityType: BaseEntityType.teams,
                        entityID: teamID,
                        seasonID: activeSeason[teamID],
                        cursor: '',
                        to: toISOStringWithTimezone(new Date()),
                        limit: '50',
                    });

                    const events = eventsResults.data?.data;

                    if (events) {
                        setEventData((prev) => [...prev, ...events]);
                    }

                    await delay(500); // Throttle requests
                }
            }
        };

        if (teams && teams.length > 0) {
            fetchTeamEvents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams, activeSeason]);

    // Calculate loading percentage and update loading state
    useEffect(() => {
        if (teams.length > 0) {
            const newLoadingPercentage = Math.round(
                (teamCount / teams.length) * 100
            );
            setLoadingPercentage(newLoadingPercentage);

            if (newLoadingPercentage >= 100) {
                setLoading(false);
            }
        }
    }, [teamCount, teams.length]);

    const handleSeasonChange = (e: { value: string }, teamID: string) => {
        const { value } = e; // Destructure to get the value from the event object

        setActiveSeason((prev) => ({
            ...prev,
            [teamID]: value,
        }));
    };

    const leftToolbar = (
        <div className="p-button-group">
            <Dropdown
                value={categoryValue}
                onChange={(e) => setCategoryValue(e.value)}
                options={[
                    { label: 'Minimum Game Time', value: 'Minimum Game Time' },
                    { label: 'Disciplinary', value: 'Disciplinary' },
                    { label: 'Injury', value: 'Injury' },
                    { label: 'Assessment', value: 'Assessment' },
                    { label: 'Opposition', value: 'Opposition' },
                ]}
            />
            <Dropdown
                value={dateRange}
                onChange={(e) => setDateRange(e.value)}
                options={[
                    { label: 'Last 7 Days', value: 'week' },
                    { label: 'Last 30 Days', value: 'month' },
                    { label: 'Entire Season', value: 'season' },
                ]}
            />
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Empty Rows
                </label>
                <InputSwitch
                    checked={hideEmptyRows}
                    onChange={(e) => setHideEmptyRows(e.value)}
                />
            </div>
        </div>
    );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const dataValue: any[] = useMemo(() => {
        let values: any[] = [];
        let newData;

        for (const team of teams) {
            const teamID = team.teamID;
            const seasonID = activeSeason[teamID];
            const report = teamReports[teamID]?.seasons[seasonID];

            if (report) {
                // data exists, but reports may not be loaded yet
                const { reports, ...reportStatus } = report;
                let filteredEvents = [];

                if (reports) {
                    filteredEvents = reports
                        .map((data) => {
                            const event = eventData.find(
                                (e) => e.eventID === data.eventID
                            );

                            return {
                                ...data,
                                team,
                                event,
                                ...reportStatus,
                            };
                        })
                        .filter((o) => {
                            if (!o.event) {
                                return false;
                            }

                            if (dateRange === 'season') {
                                return true;
                            } else if (dateRange === 'week') {
                                return isWithinLast7Days(
                                    new Date(o.event.startDateTime)
                                );
                            } else if (dateRange === 'month') {
                                return isWithinLast30Days(
                                    new Date(o.event.startDateTime)
                                );
                            }
                            return false;
                        });
                }

                newData =
                    filteredEvents.length > 0
                        ? filteredEvents
                        : [
                              {
                                  teamID,
                                  team,
                                  ...reportStatus,
                              },
                          ];
            } else {
                // No data yet
                newData = [
                    {
                        teamID,
                        team,
                    },
                ];
            }

            values = values.concat(newData);
        }

        return orderBy(values, 'event.startDateTime', 'asc');
    }, [activeSeason, teamReports, eventData, dateRange, teams]);

    const filteredData = dataValue.filter((row) => {
        let list: string[] | undefined;
        if (categoryValue === 'Disciplinary') {
            list = row.disciplineList;
        } else if (categoryValue === 'Injury') {
            list = row.injuryList;
        } else if (categoryValue === 'Opposition') {
            list = row.oppositionList;
        } else if (categoryValue === 'Assessment') {
            list = row.assessmentList;
        } else {
            list = row.pBelowTarget;
        }

        // Check if list is defined and is an array of strings
        const isListArray = Array.isArray(list);
        const containsOnlyStrings =
            isListArray && list?.every((i) => typeof i === 'string');
        const isEmptyOrNotStringList =
            !isListArray || list?.length === 0 || !containsOnlyStrings;

        if (hideEmptyRows) {
            return !isEmptyOrNotStringList;
        }

        return true;
    });

    // Get a list of duplicate playerIDs to format in the required column
    const findDuplicatesWithCount = (playerData: any[]) => {
        const playerIDCount: { [key: string]: number } = {};

        const extractPlayerIDs = (list: any): string[] => {
            if (Array.isArray(list) && typeof list[0] === 'string') {
                return list;
            }
            if (Array.isArray(list) && typeof list[0] === 'object') {
                return list.map((item) => item.playerID || '0');
            }
            return [];
        };

        filteredData.forEach((filteredData) => {
            let list: string[] | undefined;

            if (categoryValue === 'Disciplinary') {
                list = extractPlayerIDs(filteredData.disciplineList);
            } else if (categoryValue === 'Injury') {
                list = extractPlayerIDs(filteredData.injuryList);
            } else if (categoryValue === 'Opposition') {
                list = extractPlayerIDs(filteredData.oppositionList);
            } else if (categoryValue === 'Assessment') {
                list = extractPlayerIDs(filteredData.assessmentList);
            } else {
                list = extractPlayerIDs(filteredData.pBelowTarget);
            }

            if (list) {
                list.forEach((playerID: string) => {
                    if (playerID !== '0') {
                        playerIDCount[playerID] =
                            (playerIDCount[playerID] || 0) + 1;
                    }
                });
            }
        });

        // Return both the duplicates and the counts
        return {
            duplicatePlayerIDs: new Set(
                Object.keys(playerIDCount).filter((id) => playerIDCount[id] > 1)
            ),
            playerIDCount,
        };
    };

    const { duplicatePlayerIDs, playerIDCount } =
        findDuplicatesWithCount(playerData);

    return (
        <PageContainer>
            <PageHeader
                title={`${
                    collectionData?.data?.collectionName || 'Collection'
                } Fair Play Report`}
            />
            <Toolbar start={leftToolbar} end={rightToolbar} />
            {loading ? (
                <ProgressBar
                    value={loadingPercentage}
                    style={{ height: '20px' }}
                />
            ) : (
                <DataTable
                    showGridlines
                    value={filteredData ? filteredData : []}
                    sortField="team.teamName"
                    sortOrder={1}
                    columnResizeMode="expand"
                    resizableColumns
                    stripedRows={true}
                    footer={tableFooter}
                    rowGroupMode="rowspan"
                    groupRowsBy={'teamID'}
                >
                    <Column
                        sortable
                        field="teamID"
                        sortField="team.teamName"
                        header="Team"
                        body={(row) => {
                            if (!row) {
                                return <Skeleton />;
                            }

                            const url = `${window.location.pathname
                                .split('/o/')
                                .slice(0, -1)
                                .join('/')}/o/${organisationID}/t/${
                                row.team.teamID
                            }`;

                            return (
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        fontWeight: '600',
                                        color: '#000',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {row.team.teamName}
                                </a>
                            );
                        }}
                    />
                    <Column
                        header="Season"
                        field="teamID"
                        body={(row) => {
                            const seasons = seasonData[row.team.teamID];

                            if (!row || !row.team || !seasons) {
                                return <Skeleton />;
                            }

                            return (
                                <Dropdown
                                    style={{ width: '100%' }}
                                    value={activeSeason[row.teamID]}
                                    onChange={(e) =>
                                        handleSeasonChange(e, row.teamID)
                                    }
                                    className={row.isError ? 'p-invalid' : ''}
                                    valueTemplate={(val) => {
                                        return (
                                            <div className="p-dropdown-value">
                                                {val && val.label}
                                                {row.isError && (
                                                    <Icon name="error" />
                                                )}
                                            </div>
                                        );
                                    }}
                                    tooltip={
                                        row.isError && 'Unable to find report.'
                                    }
                                    options={seasons.map((season) => ({
                                        label: season.seasonName,
                                        value: season.seasonID,
                                    }))}
                                />
                            );
                        }}
                    />
                    <Column
                        header="Event"
                        body={(row) => {
                            if (!row || !row.isInitialized || row.isLoading) {
                                return <Skeleton />;
                            }

                            if (row.event) {
                                const url = `${window.location.pathname
                                    .split('/o/')
                                    .slice(0, -1)
                                    .join('/')}/o/${organisationID}/t/${
                                    row.team.teamID
                                }/reports/time-${licenceLevel}`;

                                return (
                                    <div>
                                        <strong>
                                            <a
                                                href={url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    color: '#000',
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {row.event.eventName}
                                            </a>
                                        </strong>
                                        <div>
                                            {row.event.startDateTime &&
                                                format(
                                                    new Date(
                                                        row.event.startDateTime
                                                    ),
                                                    'MMM d, YYY'
                                                )}
                                        </div>
                                    </div>
                                );
                            }

                            return 'No Games';
                        }}
                    />
                    <Column
                        header={
                            categoryValue === 'Disciplinary'
                                ? 'Players with disciplinary action'
                                : categoryValue === 'Injury'
                                ? 'Players listed as injured in game'
                                : categoryValue === 'Opposition'
                                ? 'Players that played for the opposition'
                                : categoryValue === 'Assessment'
                                ? 'Players placed into assessment'
                                : 'Players below 60% Game Time'
                        }
                        body={(row) => {
                            if (!row || !row.isInitialized || row.isLoading) {
                                return <Skeleton />;
                            }

                            let list: string[] | undefined;
                            if (categoryValue === 'Disciplinary') {
                                list = row.disciplineList;
                            } else if (categoryValue === 'Injury') {
                                list = row.injuryList;
                            } else if (categoryValue === 'Opposition') {
                                list = row.oppositionList;
                            } else if (categoryValue === 'Assessment') {
                                list = row.assessmentList;
                            } else {
                                list = row.pBelowTarget;
                            }

                            if (
                                list &&
                                list.every((i: any) => typeof i === 'string')
                            ) {
                                return (
                                    <div>
                                        {list.map((playerID: string) => {
                                            const player = playerData.find(
                                                (p) => p.playerID === playerID
                                            );

                                            if (playerID === '0') {
                                                return null; // Skip '0' player
                                            }

                                            const isDuplicate =
                                                duplicatePlayerIDs.has(
                                                    playerID
                                                ); // Check if the player is a duplicate
                                            const count =
                                                playerIDCount[playerID] || 1; // Get the occurrence count

                                            return (
                                                <div
                                                    key={playerID}
                                                    style={{
                                                        color: isDuplicate
                                                            ? 'red'
                                                            : 'black', // Highlight duplicates in red
                                                        fontWeight: isDuplicate
                                                            ? 'bold'
                                                            : 'normal',
                                                    }}
                                                >
                                                    {player
                                                        ? `${
                                                              player.uniformNumber
                                                          }. ${
                                                              player.firstName
                                                          } ${
                                                              player.lastName
                                                          } ${
                                                              count > 1
                                                                  ? `(count: ${count})`
                                                                  : ''
                                                          }`
                                                        : `Unknown player`}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        }}
                    />

                    <Column
                        header="Public Notes"
                        body={(row) => {
                            if (!row || !row.event || !notesData[row.teamID]) {
                                return 'No Public Notes';
                            }

                            // Find the note that matches the eventID and has "Public" privacy
                            const note = notesData[row.teamID].find((note) =>
                                note.nodeList.some(
                                    (node: any) =>
                                        node.nodeID === row.event.eventID
                                )
                            );
                            return note && note?.notePrivacy === 'Public' ? (
                                <div>
                                    <strong>{note.noteTitle}</strong>
                                    <div>{note.noteContent}</div>
                                </div>
                            ) : (
                                'No Public Notes'
                            );
                        }}
                    />
                </DataTable>
            )}
        </PageContainer>
    );
};

export default AssocCompetitionPGTReport;

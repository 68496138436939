import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { groupBy, sortBy } from 'lodash';

import { useGetGameInterchangeOnOffReportQuery } from '../../api/reports';
import { useLazyGetPlayersQuery } from '../../api/players';

import { defaultReportState } from './constants';
import { InterchangeStat, ReportState } from '../../types/reports';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';

import ErrorDisplay from '../../components/ErrorDisplay';
import { ERROR_TYPES } from '../../types/common';
import TextSwap from '../../components/TextSwap';

import { Mixpanel } from '../../util/mixpanel';
import RookieButton from '../../components/RookieButton';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { playerGroupCell, playerNameCell } from './DataTableCells';
import { formatTime, periodSuffix } from '../../util/helper';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Player } from '../../types/team';

enum TIME_FORMAT {
    duration = 'duration',
    timestamp = 'timestamp',
}

const GameInterchangeOnOffReport = () => {
    // Route hooks
    const { teamID, eventID } = useParams();

    // Ref hooks
    const timestampRef = useRef(Date.now()).current; // for report cache busting
    const dataTable = useRef<DataTable<any>>(null);

    // State hooks
    const [reportData, setReportData] =
        useState<ReportState<InterchangeStat[]>>(defaultReportState);
    const [players, setPlayers] = useState<Player[]>([]);
    const [timeFormat, setTimeFormat] = useState<TIME_FORMAT>(
        TIME_FORMAT.duration
    );

    // API hooks
    const [fetchPlayers, { originalArgs: playerArgs }] =
        useLazyGetPlayersQuery();

    const requestReportData = useGetGameInterchangeOnOffReportQuery(
        {
            eventID: eventID || '',
            teamID: teamID || '',
            sessionID: timestampRef,
        },
        {
            skip: !teamID || !eventID,
        }
    );

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl) {
            setReportData((prev) => ({
                ...prev,
                error: null,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        }
    }, [requestReportData]);

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            !reportData.isUninitialized &&
            !reportData.isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData.data?.some(
                (stat: InterchangeStat) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, playerArgs]);

    useEffect(() => {
        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: InterchangeStat) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, reportData.isLoading]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Expert Player Time',
        });
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const emptyMessage = () => {
        const status = requestReportData?.data?.data?.reportStatus;

        if (
            ['ErrorEvent', 'ErrorServer'].includes(status) ||
            requestReportData.isError ||
            reportData.isError
        ) {
            const errorMsg = requestReportData?.data?.data?.errorMessage;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={'An error has occurred.'}
                    desc={
                        errorMsg ||
                        'Try refreshing, if the issue persists please contact support'
                    }
                    errorType={ERROR_TYPES.somethingsWrong}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        if (['Queued', 'Started', 'Building'].includes(status)) {
            const msgs = [
                'Downloading Database...',
                'Fetching Players...',
                'Fetching Time Data...',
            ];
            const eta = status === 'Queued' ? 5 : status === 'Started' ? 4 : 3;

            return (
                <ErrorDisplay
                    hasReturn={false}
                    title={
                        <TextSwap
                            strings={msgs}
                            animationDuration="4s"
                            animationType="slideUp"
                            interval={4000}
                        />
                    }
                    desc={`Your report is getting built. Please refresh the page after ${eta} minutes.`}
                    errorType={ERROR_TYPES.maintenance}
                    actions={[
                        {
                            label: 'Refresh',
                            command: () => window.location.reload(),
                        },
                    ]}
                />
            );
        }

        return (
            <ErrorDisplay
                hasReturn={false}
                title={'No game data found.'}
                desc="If you believe this to be an error, please contact support."
                errorType={ERROR_TYPES.empty}
                actions={[
                    {
                        label: 'Support',
                        command: () =>
                            window
                                .open(
                                    'https://www.rookieme.com/contact',
                                    '_blank'
                                )
                                ?.focus(),
                    },
                ]}
            />
        );
    };

    const data = useMemo(() => {
        const rawData = reportData?.data || [];

        // merge endTime part of the same rotation
        const merged = rawData.reduce((acc, item) => {
            const key = `${item.playerID}-${item.quarter}-${item.rotation}`;

            acc[key] = acc[key] || item;
            acc[key].endTime = item.endTime;

            return acc;
        }, {} as { [keyof: string]: InterchangeStat });

        return Object.values(merged);
    }, [reportData]);

    const groupByPeriod = groupBy(data, 'quarter');

    const maxRotationsPerPeriod = Object.keys(groupByPeriod).reduce(
        (res, key) => {
            const items = groupByPeriod[key];
            const groupByPlayer = groupBy(items, 'playerID');

            res[key] = Object.values(groupByPlayer).reduce((a, b) => {
                let count = b.length;

                if (b[0].position === 'Bench') {
                    count++;
                }

                return Math.max(a, count);
            }, -Infinity);

            return res;
        },
        {} as any
    );

    const tableData = useMemo(() => {
        const grouped = groupBy(data, 'playerID');

        const updatedData = Object.keys(grouped).reduce((result, key) => {
            const rotations = grouped[key];
            let collect = {} as any;

            // Used to reindex per period
            let index = 1;

            rotations.forEach((item) => {
                if (collect[`p${item.quarter}-r${index}`]) {
                    index++;
                } else {
                    index = 1;
                }

                // Add empty dataset when starting on bench to offset initial ON column
                if (item.position === 'Bench' && index === 1) {
                    collect[`p${item.quarter}-r${index}`] = {
                        start: null,
                        end: null,
                        position: item.position,
                    };
                    index++;
                }

                collect[`p${item.quarter}-r${index}`] = {
                    start: item.startTime,
                    end: item.endTime,
                    position: item.position,
                };
            });

            result[key] = {
                playerID: key,
                player: rotations[0].player,
                ...collect,
            };

            return result;
        }, {} as any);

        const updatedDataArr = Object.values(updatedData);

        return sortBy(
            updatedDataArr,
            (s: any) => s.player && Number(s.player.uniformNumber)
        );
    }, [data]);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Player" colSpan={3} />
                {Object.keys(maxRotationsPerPeriod).map((period) => {
                    return (
                        <Column
                            header={`${period}${periodSuffix(Number(period))}`}
                            colSpan={maxRotationsPerPeriod[period]}
                        />
                    );
                })}
            </Row>
            <Row>
                <Column header="#" />
                <Column header="Player" />
                <Column header="Group" />

                {Object.keys(maxRotationsPerPeriod).map((period) =>
                    Array(maxRotationsPerPeriod[period])
                        .fill(undefined)
                        .map((val, idx) => {
                            const label = idx % 2 === 0 ? 'ON' : 'OFF';
                            return (
                                <Column
                                    header={label}
                                    field={`p${period}-r${idx + 1}`}
                                />
                            );
                        })
                )}
            </Row>
        </ColumnGroup>
    );

    const renderTime = (row: any, period: string, index: number) => {
        const rotation = row[`p${period}-r${index + 1}`];
        if (!rotation) {
            return null;
        }

        const startTime = rotation.start;
        const endTime = rotation.end;

        if (index === 0 && startTime === null) {
            return 'X';
        }

        if (timeFormat === TIME_FORMAT.timestamp) {
            return formatTime(startTime);
        }

        return formatTime(endTime - startTime);
    };

    return (
        <>
            <Toolbar
                start={
                    <Dropdown
                        options={[
                            {
                                label: 'Duration',
                                value: 'duration',
                            },
                            {
                                label: 'Timestamp',
                                value: 'timestamp',
                            },
                        ]}
                        onChange={(e) => setTimeFormat(e.value)}
                        value={timeFormat}
                    />
                }
                end={
                    <RookieButton
                        type="button"
                        onClick={() => exportCSV()}
                        label="Export CSV"
                        severity="secondary"
                    />
                }
            />
            <DataTable
                ref={dataTable}
                value={
                    requestReportData.isLoading || reportData.isLoading
                        ? Array(5)
                        : tableData
                }
                footer={tableFooter}
                emptyMessage={emptyMessage()}
                columnResizeMode="expand"
                resizableColumns
                exportFilename="game-interchange-report"
                headerColumnGroup={headerGroup}
                showGridlines
            >
                <Column header="#" field="player.uniformNumber" sortable />
                <Column
                    header="Player"
                    field="player.lastName"
                    body={playerNameCell}
                    sortable
                    exportable={false}
                />
                <Column
                    header="First Name"
                    field="player.firstName"
                    hidden={true}
                />
                <Column
                    header="Last Name"
                    field="player.lastName"
                    hidden={true}
                />
                <Column
                    header="Group"
                    field="player.colour"
                    body={playerGroupCell}
                    sortable
                />
                {Object.keys(maxRotationsPerPeriod).map((period, pIndex) =>
                    Array(maxRotationsPerPeriod[period])
                        .fill(undefined)
                        .map((val, idx) => {
                            return [
                                <Column
                                    header={idx + 1}
                                    body={(row) => renderTime(row, period, idx)}
                                    exportable={false}
                                    style={{
                                        textAlign: 'center',
                                        minWidth: '120px',
                                    }}
                                />,
                                [
                                    <Column
                                        header={`Period ${
                                            pIndex + 1
                                        } - Rotation ${idx + 1}`}
                                        field={`p${period}-r${idx + 1}.end`}
                                        hidden={true}
                                    />,
                                ],
                            ];
                        })
                )}
            </DataTable>
        </>
    );
};

export default GameInterchangeOnOffReport;

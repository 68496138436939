import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import { Tree, TreeEventNodeEvent } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';

import { BaseEntityType } from '../../types/common';

interface Props {
    data: TreeNode[];
    entityType: BaseEntityType;
    entityID: string;
    onNodeSelect: (event: TreeEventNodeEvent) => void;
}

const ResourcesView = (props: Props) => {
    const { data, onNodeSelect } = props;

    return (
        <PageContainer>
            <PageHeader title="Resources" />

            <Tree
                value={data}
                onSelect={onNodeSelect}
                dragdropScope="demo"
                //onDragDrop={(e) => setTreeData(e.value as TreeNode[])}
                selectionMode="single"
                filter
                filterMode="lenient"
                filterPlaceholder="Search..."
            />
        </PageContainer>
    );
};

export default ResourcesView;

import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDeleteGroupMutation, useGetGroupsQuery } from '../../api/groups';

import GroupsView from './GroupsView';
import usePermission from '../../hooks/usePermission';
import { ToastContext } from '../../contexts/ToastContext';

import { getEntityFromParam } from '../../util/helper';

import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import Icon from '../../components/Icon';
import DocumentHead from '../../components/DocumentHead';

import { Group } from '../../types/groups';
import { Permissions } from '../../types/permissions';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';

interface Props {
    roles: Roles;
    route: Route;
}

const UserGroupsContainer = (props: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const [editing, setEditing] = useState<Group | null>(null);
    const [showFormModal, setShowFormModal] = useState(false);
    const [cursor, setCursor] = useState('');

    const toast = useContext(ToastContext);

    const { data, isLoading, isError, isFetching } = useGetGroupsQuery(
        // @ts-expect-error
        { ...activeEntity, cursor },
        {
            skip: !activeEntity,
        }
    );

    const [deleteGroup] = useDeleteGroupMutation();

    // Permission Hook
    const { checkPermission } = usePermission(activeEntity?.entityID || '');

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleEditGroup = (group: Group) => {
        setShowFormModal(true);
        setEditing(group);
    };

    const handleDeleteGroup = (groupID: string) => {
        if (activeEntity) {
            confirmDialog({
                message: `Are you sure you want to delete this group?`,
                header: 'Delete Group',
                icon: <Icon name="dangerous" />,
                accept: () =>
                    deleteGroup({ ...activeEntity, groupID })
                        .then((response) => {
                            if ('error' in response) {
                                showToast({
                                    severity: 'error',
                                    detail: 'There was an error deleting the group. Please try again.',
                                    summary: 'Error',
                                });
                            } else {
                                showToast({
                                    severity: 'success',
                                    detail: 'Successfully updated group.',
                                });
                            }
                        })
                        .catch((err) => {
                            showToast({
                                severity: 'error',
                                detail: err.error,
                                summary: 'Error',
                            });
                        }),
            });
        }
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && data.lastEvaluatedKey.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };

    const handleCloseModal = () => {
        setShowFormModal(false);
        setEditing(null);
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Groups - Rookie Me Hub"
                description="Groups page"
            />
            <GroupsView
                entityType={activeEntity?.entityType}
                entityID={activeEntity?.entityID}
                data={data?.data || []}
                editing={editing}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                onLoadMore={handleLoadMore}
                onEdit={handleEditGroup}
                onDelete={handleDeleteGroup}
                onOpenCreateModal={() => setShowFormModal(true)}
                onCloseCreateModal={handleCloseModal}
                showFormModal={showFormModal}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                permissions={permissions}
                {...props}
            />
        </>
    );
};

export default UserGroupsContainer;

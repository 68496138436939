import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';

import { ERROR_TYPES } from '../../types/common';
import { Ticket } from '../../types/ticket';

export interface Props {
    data: Ticket[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onShowRevokeDialog: () => void;
    onCloseRevokeDialog: () => void;
    onShowResendDialog: () => void;
    onCloseResendDialog: () => void;
    onRevokeUser: (ticket: Ticket) => void;
    onResendInvite: (ticket: Ticket) => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
    showRevokeDialog: boolean;
    showResendDialog: boolean;
}

const StaffPendingView = (props: Props) => {
    const {
        isError,
        isFetching,
        isLoading,
        data,
        onShowResendDialog,
        onCloseResendDialog,
        showResendDialog,
        onResendInvite,
        onRevokeUser,
    } = props;
    const navigate = useNavigate();
    const rowMenu = useRef<Menu>(null);
    const staffToast = useRef<Toast>(null);
    const [showRevokeDialog, setShowRevokeDialog] = useState(false);
    const [focusedTicket, setFocusedTicket] = useState<Ticket | null>(null);
    const [menuItems, setMenuItems] = useState<any[]>([]);

    const blankRows = Array(5);

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : []
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No staff found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Staff' in the top right area."
            }
        />
    );

    const getTdMenuItems = (rowData: Ticket) => {
        let items = [];

        if (props.permissions.canCreate) {
            items.push({
                label: 'Resend Invite',
                icon: <Icon name="mail" className="p-menuitem-icon" />,
                command: () => {
                    onShowResendDialog();
                    setFocusedTicket(rowData);
                },
            });
        }

        if (props.permissions.canDelete) {
            items.push({
                label: 'Revoke Invite',
                icon: <Icon name="cancel" className="p-menuitem-icon" />,
                command: () => {
                    setShowRevokeDialog(true);
                    setFocusedTicket(rowData);
                },
            });
        }

        return items;
    };

    const actionTemplate = (rowData: Ticket) => {
        return (
            <RookieButton
                severity="secondary"
                text={true}
                className="td-action"
                aria-label="More"
                onClick={(e) => {
                    if (rowMenu.current) {
                        rowMenu.current.toggle(e);
                        setMenuItems(getTdMenuItems(rowData)); // Update the menu items when the button is clicked
                    }
                }}
                icon="more_horiz"
            />
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            field: 'metadata.target',
            header: 'Email',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) => {
                    return format(new Date(row.lastEdited), 'dd/MM/yyyy');
                }
            ),
            field: 'lastEdited',
            header: 'Last Sent',
        },
        {
            field: 'actions',
            header: '',
            body: actionTemplate,
            style: {
                textAlign: 'right',
            },
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    return (
        <>
            {props.isLoading ? (
                <div>Loading...</div>
            ) : (
                <DataTable
                    value={isLoading ? blankRows : data}
                    loading={isFetching && !isLoading}
                    emptyMessage={tableEmptyMessage}
                    columnResizeMode="expand"
                    resizableColumns
                >
                    {isLoading ? columns : data.length > 0 ? columns : null}
                </DataTable>
            )}
            <Dialog
                className="member-form-modal delete-modal"
                header="Revoke Invite"
                onHide={() => setShowRevokeDialog(false)}
                visible={showRevokeDialog}
            >
                {focusedTicket && (
                    <div>
                        <p className="delete-msg">
                            Are you sure you want to revoke this invite?
                        </p>
                        <div className="form-actions">
                            <RookieButton
                                text={true}
                                severity="secondary"
                                onClick={() => setShowRevokeDialog(false)}
                                type="button"
                                label="Cancel"
                                icon="cancel"
                            />
                            <RookieButton
                                severity="danger"
                                type="submit"
                                onClick={() => {
                                    if (focusedTicket) {
                                        onRevokeUser(focusedTicket);
                                    }
                                    setShowRevokeDialog(false);
                                }}
                                icon="delete"
                                label="Revoke Invite"
                            />
                        </div>
                    </div>
                )}
            </Dialog>

            <Dialog
                className="member-form-modal resend-modal"
                header="Resend Invite"
                onHide={onCloseResendDialog}
                visible={showResendDialog}
            >
                {focusedTicket && (
                    <div>
                        <p className="resend-msg">
                            Are you sure you want to resend this invite?
                        </p>
                        <div className="form-actions">
                            <RookieButton
                                text={true}
                                severity="secondary"
                                onClick={onCloseResendDialog}
                                type="button"
                                icon="cancel"
                                label="Cancel"
                            />
                            <RookieButton
                                type="submit"
                                onClick={() => {
                                    // Add your resend function here
                                    if (focusedTicket) {
                                        onResendInvite(focusedTicket);
                                    }
                                    onCloseResendDialog();
                                }}
                                icon="mail"
                                label="Resend Invite"
                            />
                        </div>
                    </div>
                )}
            </Dialog>

            <Menu model={menuItems} popup ref={rowMenu} />
            <Toast ref={staffToast} />
        </>
    );
};

export default StaffPendingView;

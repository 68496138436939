import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { unionBy, uniq, orderBy, sortBy } from 'lodash';

import { useGetEventsQuery } from '../../api/events';
import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import { useLazyGetPlayersQuery } from '../../api/players';
import {
    useGetTeamSeasonTimeBasicReportQuery,
    useGetTeamSeasonTimeAdvancedReportQuery,
    useGetTeamSeasonTimeExpertReportQuery,
} from '../../api/reports';
import { useGetTeamQuery } from '../../api/teams';

import Icon from '../../components/Icon';
import PageHeader from '../../layout/PageHeader';

import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { Column, ColumnSortEvent } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { periodSuffix } from '../../util/helper';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { config, sportSpecificColumns } from './configTime';
import {
    formatPercentage,
    formatTime,
    toISOStringWithTimezone,
} from '../../util/helper';
import { defaultReportState } from '../reports/constants';
import { calculateAverages, calculateTotals } from './helpers';
import {
    playerAlertTargetCell,
    playerGroupCell,
    playerNameCell,
} from '../reports/DataTableCells';
import { PlayerAlertModes } from '../../types/game';
import PlayerAlertButton from '../reports/PlayerAlertButton';
import PlayerAlertSettings from '../reports/PlayerAlertSettings';

import { Event } from '../../types/event';
import {
    ReportDataTypes,
    ReportState,
    StatConfig,
    StatTypes,
    TimeDisplays,
    TimeStat,
} from '../../types/reports';
import { InputSwitch } from 'primereact/inputswitch';
import ErrorDisplay from '../../components/ErrorDisplay';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { Mixpanel } from '../../util/mixpanel';
import { Player } from '../../types/team';

interface DateCellProps {
    type: StatTypes;
    value: any;
    totalTime: number;
    showTimeAsPercentage: boolean;
    stat: string;
    data: TimeStat;
}

interface Props {
    reportType: 'basic' | 'advanced' | 'expert';
}

const DataCell = ({
    stat,
    type,
    value,
    totalTime,
    showTimeAsPercentage,
    data,
}: DateCellProps) => {
    const component = useMemo(() => {
        return {
            [StatTypes.Count]: value,
            [StatTypes.Percentage]: formatPercentage(value),
            [StatTypes.Time]: showTimeAsPercentage
                ? formatPercentage(value / totalTime)
                : formatTime(value),
        }[type];
    }, [type, value, totalTime, showTimeAsPercentage]);

    let newVal = component ? component : value;

    if (stat) {
        const main = stat.replace('time', '');

        if (data[`countStint${main}`]) {
            let roundedCountStint = Math.round(data[`countStint${main}`]);
            return `${newVal} (${roundedCountStint})`;
        }
    }

    return newVal;
};

const TimeReport = ({ reportType }: Props) => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('eventID');
    const seasonParam = searchParams.get('seasonID');
    const categoryParam = searchParams.get('category');

    // State Hooks
    const [dataType, setDataType] = useState(ReportDataTypes.Total);
    const [timeDisplay, setTimeDisplay] = useState<TimeDisplays>(
        TimeDisplays.Percentage
    );
    const [season, setSeason] = useState<string | null>(seasonParam || null);
    const [event, setEvent] = useState<string>(eventParam || '');
    const [category, setCategory] = useState<string>(categoryParam || '');
    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [reportData, setReportData] =
        useState<ReportState<TimeStat>>(defaultReportState);
    const [players, setPlayers] = useState<Player[]>([]);
    const [filter, setFilter] = useState(0); // for period filter
    // State hooks for player alert button
    const [playerAlertMode, setPlayerAlertMode] = useState<PlayerAlertModes>(
        PlayerAlertModes.None
    );
    const [playerAlertTarget, setPlayerAlertTarget] = useState(0.5);
    const playerAlertOverlay = useRef<OverlayPanel>(null);

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    const reportParams = {
        seasonID: season || '',
        teamID: teamID || '',
        sessionID: timestampRef,
    };

    // API Hooks
    const [
        fetchPlayers,
        { originalArgs: playerArgs, isLoading: isLoadingPlayers },
    ] = useLazyGetPlayersQuery();

    const requestBasicReport = useGetTeamSeasonTimeBasicReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'basic',
        }
    );
    const requestAdvancedReport = useGetTeamSeasonTimeAdvancedReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'advanced',
        }
    );
    const requestExpertReport = useGetTeamSeasonTimeExpertReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType !== 'expert',
        }
    );

    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    const teamSportID = teamData?.data?.data.entitySportID as string;

    const requestReportData =
        reportType === 'expert'
            ? requestExpertReport
            : reportType === 'advanced'
            ? requestAdvancedReport
            : requestBasicReport;

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
    });

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Fetch report
    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl && requestReportData.isSuccess) {
            setReportData({
                ...defaultReportState,
                isLoading: true,
                isUninitialized: false,
            });

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setReportData(defaultReportState);
        }
    }, [requestReportData]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(teamData?.data?.data.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, teamData]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (event !== '' && dataType === ReportDataTypes.Average) {
            setDataType(ReportDataTypes.Total);
        }
    }, [event, dataType]);

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            !reportData.isUninitialized &&
            !reportData.isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData.data?.some(
                (stat: any) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, playerArgs]);

    // Inject player details into data
    useEffect(() => {
        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: any) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, reportData.isLoading]);

    // Filter Options
    const eventOptions = useMemo(() => {
        let options = [
            {
                label: 'Entire Season',
                value: '',
                error: false,
            },
        ];

        const availableGames = uniq(
            reportData?.data?.map((val: TimeStat) => val.gameID)
        );

        if (eventData.data) {
            eventData.data.data.forEach((event: Event) => {
                options.push({
                    label: event.eventName,
                    value: event.eventID,
                    error: !availableGames.includes(event.eventID),
                });
            });
        }

        // Filter out options where error is true
        return options.filter((option) => !option.error);
    }, [reportData, eventData]);

    const totalEventsCount = useMemo(() => {
        return uniq(reportData?.data?.map((o: TimeStat) => o.gameID)).length;
    }, [reportData]);

    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'All Categories',
                value: '',
            },
        ];

        const availableCategories = uniq(
            (teamSportID ? sportSpecificColumns[teamSportID][reportType] : [])
                .map((val) => {
                    const stat = config[val as keyof typeof config];
                    return stat && stat.category;
                })
                .filter((val) => !!val)
        );

        availableCategories.forEach((cat) => {
            options.push({
                label: cat,
                value: cat,
            });
        });

        return options;
    }, [reportType, teamSportID]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    const timeDisplayOptions = [
        {
            label: '% Per',
            value: TimeDisplays.Percentage,
        },
        {
            label: 'Time',
            value: TimeDisplays.Time,
        },
    ];

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Player Time`,
        });
    };

    const filteredData = useMemo(() => {
        let filtered = reportData.data || [];

        const brokenEvents = filtered
            .filter((item: TimeStat) => item.timePos__Field > 0)
            .map((item: TimeStat) => item.gameID);

        filtered = filtered.filter(
            (item: TimeStat) => !brokenEvents.includes(item.gameID)
        );

        filtered = filtered.filter(
            (item: TimeStat) => filter === 0 || item.period === filter
        );

        // Apply category filter
        if (event !== '') {
            filtered = filtered.filter(
                (item: TimeStat) => item.gameID === event
            );
        }

        const sorted = sortBy(
            filtered,
            (item: TimeStat) => item.player && Number(item.player.uniformNumber)
        );
        return sorted;
    }, [reportData, event, filter]); // Recalculate when rawData or filter changes

    // Get total periods for the individual game
    let totalPeriods = 0;
    let periodFilteredData = reportData.data || [];
    if (event !== '') {
        const filteredData = periodFilteredData.filter(
            (item: TimeStat) => item.gameID === event
        );

        totalPeriods = (filteredData as TimeStat[]).reduce(
            (maxPeriod, item: TimeStat) => {
                return item.period > maxPeriod ? item.period : maxPeriod;
            },
            0
        );
    }

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    // Renders
    const leftToolbar = (
        <div className="p-button-group">
            <>
                <Dropdown
                    onChange={(e) => setSeason(e.value)}
                    value={season}
                    options={seasonOptions}
                />
                <Dropdown
                    onChange={(e) => setEvent(e.value)}
                    value={event}
                    options={eventOptions}
                    itemTemplate={(o) => {
                        return (
                            <div
                                style={{
                                    opacity: o.error ? 0.5 : 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {o.label} {o.error && <Icon name="error" />}
                            </div>
                        );
                    }}
                />
                {categoryOptions.length > 2 && (
                    <Dropdown
                        onChange={(e) => setCategory(e.value)}
                        value={category}
                        options={categoryOptions}
                    />
                )}
                {event && (
                    <SelectButton
                        value={filter}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setFilter(e.value)}
                        options={[{ label: 'All', value: 0 }, ...periodOptions]}
                        disabled={event === ''}
                    />
                )}
                {(reportType === 'advanced' || reportType === 'expert') && (
                    <PlayerAlertButton
                        mode={playerAlertMode}
                        onClick={(e) =>
                            playerAlertOverlay.current &&
                            playerAlertOverlay.current.toggle(e)
                        }
                        value={`${Math.round(playerAlertTarget * 100)}%`}
                    />
                )}
            </>
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            {reportType !== 'basic' && (
                <div
                    className="p-button p-button-text p-button-plain"
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '5px',
                        border: '1px solid #ced4da',
                        padding: '8px 15px',
                        borderRadius: '2px',
                        fontSize: '14px',
                        fontWeight: 'bold',
                    }}
                >
                    <label
                        style={{
                            margin: '0',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#4f4f4f',
                        }}
                    >
                        Hide Empty Cols
                    </label>
                    <InputSwitch
                        checked={hideEmptyColumns}
                        onChange={(e) => setHideEmptyColumns(e.value)}
                    />
                </div>
            )}
            <SelectButton
                value={timeDisplay}
                onChange={(e) => setTimeDisplay(e.value)}
                options={timeDisplayOptions}
            />
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
                disabled={event !== ''}
            />
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderHeaderCell = (stat: StatConfig) => {
        const { description, hsArticle } = stat;

        return (
            <>
                <Tooltip
                    target={`.cell-${stat.shortName}`}
                    autoHide={false}
                    position="right"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>
                            <div>{stat.name}</div>
                            {showInfo && <div>{stat.description}</div>}
                        </div>
                        {hsArticle ? (
                            <RookieButton
                                data-beacon-article={hsArticle}
                                text={true}
                                icon="help"
                            />
                        ) : (
                            description && (
                                <RookieButton
                                    onClick={() => setShowInfo(!showInfo)}
                                    text={true}
                                    icon={showInfo ? 'close' : 'info'}
                                />
                            )
                        )}
                    </div>
                </Tooltip>
                {stat.shortName}
            </>
        );
    };

    const renderEmpty = () => {
        const msg = event
            ? "Can't load report for this event. The data may be corrupt. Try rebuilding your report or reach out to us via the question mark at the base of the page."
            : 'Unable to find report. Please try again later';
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ErrorDisplay
                    alignment="middle"
                    title="No Report Found"
                    desc={msg}
                    errorType={errorType}
                    hasReturn={false}
                    proportion="compact"
                />
            </div>
        );
    };

    const calculatedData = useMemo(() => {
        return dataType === ReportDataTypes.Average
            ? calculateAverages(filteredData, 'playerID', totalEventsCount)
            : calculateTotals(filteredData, 'playerID');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, players, dataType]);

    const calculatePlayedCount = (row: TimeStat) => {
        let value = null;
        if (reportData && reportData.data) {
            value = reportData.data.filter(
                (o: TimeStat) => o.playerID === row.playerID && o.period === 1
            ).length;

            if (dataType === ReportDataTypes.Average) {
                value = formatPercentage(value / totalEventsCount);
            }
        }

        return value;
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const sortTimePercentages = ({ data, order, field }: ColumnSortEvent) =>
        order &&
        orderBy(data, (o) => o[field] / o.PT, order > 0 ? 'asc' : 'desc');

    const sortByGamesPlayed = ({ data, order }: ColumnSortEvent) =>
        order &&
        orderBy(
            data,
            (o) => calculatePlayedCount(o),
            order > 0 ? 'asc' : 'desc'
        );

    return (
        <PageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader title="Time Report" />
                <RookieButton
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        opacity: 'inherit',
                        marginLeft: '-50px', // Use a negative margin to reduce space
                        marginTop: '50px',
                    }}
                    text={true}
                    data-beacon-article="672457dc1cdd50682627a79d"
                    icon="help"
                />
            </div>
            <Toolbar start={leftToolbar} end={rightToolbar} />
            <div>
                <DataTable
                    ref={dataTable}
                    removableSort
                    showGridlines
                    value={
                        reportData.isLoading || reportData.isUninitialized
                            ? Array(5)
                            : calculatedData
                    }
                    emptyMessage={renderEmpty()}
                    scrollable
                    footer={tableFooter}
                    scrollHeight="100vh"
                    columnResizeMode="expand"
                    resizableColumns
                    exportFilename={`season-${reportType}-time-report`}
                    stripedRows={true}
                >
                    <Column
                        header="#"
                        align="center"
                        alignHeader="center"
                        sortable
                        field="player.uniformNumber"
                        frozen
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                    <Column
                        frozen
                        sortable
                        header="Player"
                        field="player.lastName"
                        body={isLoadingPlayers ? <Skeleton /> : playerNameCell}
                        exportable={false}
                        headerStyle={{ backgroundColor: 'white' }}
                    />
                    <Column
                        header="First Name"
                        field="player.firstName"
                        hidden={true}
                    />
                    <Column
                        header="Last Name"
                        field="player.lastName"
                        hidden={true}
                    />
                    <Column
                        sortable
                        header="Group"
                        field="player.colour"
                        body={isLoadingPlayers ? <Skeleton /> : playerGroupCell}
                        headerStyle={{ backgroundColor: 'white' }}
                    />

                    {event === '' && (
                        <Column
                            align="center"
                            alignHeader="center"
                            sortable
                            sortFunction={sortByGamesPlayed}
                            body={(row) =>
                                !row || reportData.isLoading ? (
                                    <Skeleton />
                                ) : (
                                    calculatePlayedCount(row)
                                )
                            }
                            header="Games"
                            headerStyle={{ backgroundColor: 'white' }}
                        />
                    )}

                    {playerAlertMode !== PlayerAlertModes.None && (
                        <Column
                            header="Target"
                            align="center"
                            alignHeader="center"
                            field="targetPGT"
                            body={(rowData: TimeStat) => {
                                const totalPGT =
                                    rowData.timeOnField /
                                    rowData.period__available_time;

                                return playerAlertTargetCell(
                                    !isNaN(totalPGT)
                                        ? Number(totalPGT.toFixed(2))
                                        : 0,
                                    playerAlertTarget
                                );
                            }}
                            headerStyle={{ backgroundColor: 'white' }}
                        />
                    )}

                    {(teamSportID
                        ? sportSpecificColumns[teamSportID][reportType]
                        : []
                    ).map((val: string) => {
                        const stat = config[val as keyof typeof config];

                        if (!stat) return null;

                        if (
                            hideEmptyColumns &&
                            (calculatedData as TimeStat[])
                        ) {
                            const emptyColumn: TimeStat[] =
                                calculatedData.filter((item: TimeStat) => {
                                    return item[val] && item[val] > 0;
                                });

                            if (emptyColumn.length <= 0 && val !== 'games') {
                                return null;
                            }
                        }

                        if (stat.category !== category && category !== '')
                            return null;

                        return [
                            <Column
                                field={val}
                                header={stat.name}
                                hidden={true}
                                exportable={
                                    stat.exportable === false ? false : true
                                }
                            />,
                            <Column
                                key={stat.shortName}
                                field={val}
                                headerStyle={{ backgroundColor: 'white' }}
                                align="center"
                                alignHeader="center"
                                sortable
                                sortFunction={
                                    stat.sortFunction
                                        ? stat.sortFunction
                                        : stat.type === StatTypes.Time &&
                                          timeDisplay ===
                                              TimeDisplays.Percentage
                                        ? sortTimePercentages
                                        : undefined
                                }
                                header={renderHeaderCell(stat)}
                                headerClassName={`cell-${stat.shortName}`}
                                body={(row, options) => {
                                    return !row || reportData.isLoading ? (
                                        <Skeleton width="2rem" height="1rem" />
                                    ) : stat.format ? (
                                        stat.format(row, options, timeDisplay)
                                    ) : (
                                        <DataCell
                                            data={row}
                                            stat={val}
                                            value={row[val]}
                                            type={stat.type}
                                            totalTime={row['PT']}
                                            showTimeAsPercentage={
                                                timeDisplay ===
                                                TimeDisplays.Percentage
                                            }
                                        />
                                    );
                                }}
                                exportable={false}
                            />,
                        ];
                    })}
                </DataTable>
                <OverlayPanel ref={playerAlertOverlay} dismissable>
                    <PlayerAlertSettings
                        onModeChange={setPlayerAlertMode}
                        onTargetChange={setPlayerAlertTarget}
                        mode={playerAlertMode}
                        target={playerAlertTarget}
                    />
                </OverlayPanel>
            </div>
        </PageContainer>
    );
};

export default TimeReport;

import { generatePath } from 'react-router-dom';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isEqual } from 'lodash';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import {
    PlayerResponse,
    Player,
    PlayerForm,
    PlayersResponse,
} from '../types/team';
import { UserDetails, UserDetailsResponse } from '../types/user';

export const playersApi = createApi({
    reducerPath: 'playersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Player', 'EntityPlayer'],
    endpoints: (builder) => ({
        getPlayers: builder.query<
            PlayersResponse,
            { teamID: string; cursor?: string; status?: string; limit?: number }
        >({
            query: ({ teamID, cursor, limit, status = 'Active' }) => ({
                url: generatePath(apiEndpoints.getPlayers.url, { teamID }),
                method: apiEndpoints.getPlayers.method,
                params: {
                    cursor,
                    status,
                    limit,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ playerID }: Player) => ({
                              type: 'Player' as const,
                              playerID,
                          })),
                          'Player',
                      ]
                    : ['Player'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];
                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getEntityPlayers: builder.query<
            UserDetailsResponse,
            { teamID: string; cursor?: string; status?: string; limit?: number }
        >({
            query: ({ teamID, cursor, limit, status = 'Active' }) => ({
                url: generatePath(apiEndpoints.getEntityPlayers.url, {
                    teamID,
                }),
                method: apiEndpoints.getEntityPlayers.method,
                params: {
                    cursor,
                    status,
                    limit,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }: UserDetails) => ({
                              type: 'EntityPlayer' as const,
                              userID,
                          })),
                          'EntityPlayer',
                      ]
                    : ['EntityPlayer'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];
                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getPlayer: builder.query<
            PlayerResponse,
            { teamID: string; playerID: string }
        >({
            query: ({ teamID, playerID }) => ({
                url: generatePath(apiEndpoints.getPlayer.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.getPlayer.method,
            }),
            providesTags: ['Player'],
        }),
        getPlayersByUser: builder.query<PlayerResponse, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getPlayersByUser.url, {
                    userID,
                }),
                method: apiEndpoints.getPlayersByUser.method,
            }),
            providesTags: ['Player'],
        }),
        createPlayer: builder.mutation<Player, PlayerForm>({
            query: ({ teamID, ...data }) => ({
                url: generatePath(apiEndpoints.createPlayer.url, { teamID }),
                method: apiEndpoints.createPlayer.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        updatePlayer: builder.mutation<Player, PlayerForm>({
            query: ({ teamID, playerID, ...data }) => ({
                url: generatePath(apiEndpoints.updatePlayer.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.updatePlayer.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        upsertPlayer: builder.mutation<Player, PlayerForm>({
            query: ({ teamID, playerID, ...data }) => ({
                url: generatePath(
                    playerID
                        ? apiEndpoints.updatePlayer.url
                        : apiEndpoints.createPlayer.url,
                    { playerID, teamID }
                ),
                method: playerID
                    ? apiEndpoints.updatePlayer.method
                    : apiEndpoints.createPlayer.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        deletePlayer: builder.mutation<
            void,
            { teamID: string; playerID: string }
        >({
            query: ({ teamID, playerID }) => ({
                url: generatePath(apiEndpoints.deletePlayer.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.deletePlayer.method,
            }),
            invalidatesTags: ['Player'],
        }),

        /**
         *
         * Linking Players To Users
         *
         **/

        linkPlayerToUser: builder.mutation({
            query: ({ teamID, playerID, ...data }) => ({
                url: generatePath(apiEndpoints.linkPlayerToUser.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.linkPlayerToUser.method,
                body: data,
            }),
            invalidatesTags: ['Player'],
        }),
        unlinkPlayerFromUser: builder.mutation({
            query: ({ teamID, playerID }) => ({
                url: generatePath(apiEndpoints.unlinkPlayerFromUser.url, {
                    teamID,
                    playerID,
                }),
                method: apiEndpoints.unlinkPlayerFromUser.method,
            }),
            invalidatesTags: ['Player'],
        }),
    }),
});

export const {
    useGetPlayerQuery,
    useLazyGetPlayersQuery,
    useGetPlayersQuery,
    useGetEntityPlayersQuery,
    useGetPlayersByUserQuery,
    useCreatePlayerMutation,
    useUpdatePlayerMutation,
    useUpsertPlayerMutation,
    useDeletePlayerMutation,
    useLinkPlayerToUserMutation,
    useUnlinkPlayerFromUserMutation,
} = playersApi;

import { useMemo, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getEntityFromParam } from '../../util/helper';
import { useGetCollectionsQuery } from '../../api/collections';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';

import { Me } from '../../types/user';

interface Report {
    title: string;
    path: string;
    content: string;
    icon: string;
    locked?: boolean;
    onClick?: (report: Report) => any;
}

interface ReportCardGroup {
    title: string;
    items: Report[];
    onClick?: (report: Report) => any;
}

interface ReportsListingProps {
    user: Me;
}

const ReportCard = (props: Report) => {
    return (
        <div
            onClick={() =>
                !props.locked && props.onClick
                    ? props.onClick(props)
                    : undefined
            }
            className={`report-card ${props.locked ? 'is-locked' : ''}`}
            style={{ cursor: props.locked ? 'not-allowed' : 'pointer' }}
        >
            <Icon name={props.icon} size="large" />
            <div>
                <h3 style={{ marginBottom: '5px' }}>
                    {props.title}{' '}
                    {props.locked && (
                        <Tag style={{ verticalAlign: 'middle', color: '#fff' }}>
                            Upgrade
                        </Tag>
                    )}
                </h3>
                <p
                    style={{
                        opacity: '0.7',
                        lineHeight: '1.3',
                        fontSize: '14px',
                    }}
                >
                    {props.content}
                </p>
            </div>
        </div>
    );
};

const ReportSection = (props: ReportCardGroup) => {
    return (
        <div
            style={{
                marginBottom: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #e5e5e5',
            }}
        >
            <h4 style={{ marginBottom: '10px' }}>{props.title}</h4>
            <div style={{ display: 'flex', gap: '20px' }}>
                {props.items.map((item) => (
                    <ReportCard
                        key={item.title}
                        onClick={props.onClick}
                        {...item}
                    />
                ))}
            </div>
        </div>
    );
};

const AssocSeasonReportsListing = (props: ReportsListingProps) => {
    const dialogRef = useRef<Dialog | null>(null);

    const params = useParams();
    const navigate = useNavigate();

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const [visible, setVisible] = useState(false);
    const [selectedReport, setSelectedReport] = useState<Report | null>(null);
    const [selectedCollection, setSelectedCollection] = useState<string | null>(
        null
    );

    const { data: collectionsData } = useGetCollectionsQuery(
        // @ts-expect-error
        { ...activeEntity, cursor: '' },
        {
            skip: !activeEntity,
        }
    );
    const collectionOptions = collectionsData?.data?.map((item) => ({
        label: item.collectionName,
        value: item.collectionID,
    }));

    const handleCardClick = (report: Report) => {
        if (
            report.title === 'Competition Environment' ||
            report.title === 'Competition Fair Play'
        ) {
            setVisible(true);
            setSelectedReport(report);
        } else {
            navigate(report.path);
        }
    };

    const reportSections: ReportCardGroup[] = [
        {
            title: 'Summary',
            items: [
                {
                    title: 'Association Environment',
                    content:
                        'View club and team summary data for environment scores, fair game time scores and much more.',
                    icon: 'nature',
                    path: 'environment',
                },
                {
                    title: 'Competition Environment',
                    content:
                        'View team summary data for the specific competition you choose to view.',
                    icon: 'emoji_events',
                    path: 'competition-environment',
                },
                {
                    title: 'Competition Fair Play',
                    content:
                        'Insight into which players have received minimum gametime or listed as either injured, disciplined, assessment or played for the opposition for the given competition.',
                    icon: 'diversity_3',
                    path: 'competition-fair-play',
                },
            ],
        },
        {
            title: 'Coming soon...',
            items: [
                {
                    title: 'Umpire reports',
                    content: 'Coming soon...',
                    icon: 'analytics',
                    path: '',
                },
                {
                    title: 'Competition voting',
                    content: 'Coming soon...',
                    icon: 'analytics',
                    path: '',
                },
            ],
        },
    ];

    return (
        <PageContainer>
            <PageHeader title="Season Reports" />
            {reportSections.map((section) => (
                <ReportSection
                    key={section.title}
                    title={section.title}
                    items={section.items}
                    onClick={handleCardClick}
                />
            ))}
            <Dialog
                ref={dialogRef}
                header="Select a Collection"
                visible={visible}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
                footer={
                    <div className="p-buttons">
                        <RookieButton
                            label="Cancel"
                            severity="secondary"
                            onClick={() => setVisible(false)}
                        />
                        <RookieButton
                            label="View Report"
                            disabled={!selectedCollection}
                            onClick={() => {
                                if (selectedCollection && selectedReport) {
                                    navigate(selectedReport.path, {
                                        state: {
                                            selectedCollection,
                                        },
                                    });
                                }
                            }}
                        />
                    </div>
                }
            >
                <div>
                    <p>
                        Please select the competition you wish to generate the
                        report.
                    </p>
                    <Dropdown
                        value={selectedCollection}
                        options={collectionOptions}
                        onChange={(e) => {
                            setSelectedCollection(e.value); // Update the outer state if needed
                        }}
                        placeholder="Select a collection."
                        style={{ width: '100%' }}
                    />
                </div>
            </Dialog>
        </PageContainer>
    );
};

export default AssocSeasonReportsListing;

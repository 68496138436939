import { useContext, useMemo, useState } from 'react';

import { ToastContext } from '../../contexts/ToastContext';
import { InputText } from 'primereact/inputtext';

import {
    useCreateCheckoutSessionMutation,
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
    useUpdateLicenceGroupMutation,
} from '../../api/licences';

import PlanCard from './PlanCard';

import { confirmDialog } from 'primereact/confirmdialog';
import { SelectButton } from 'primereact/selectbutton';
import { ToastMessage } from 'primereact/toast';

import { BaseEntityType } from '../../types/common';
import { LicenceType, RookieProduct } from '../../types/licences';
import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { Skeleton } from 'primereact/skeleton';
import Loader from '../../components/Loader';
import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';
import { useGetEntityQuery } from '../../api/core';

enum SubmitStatus {
    Submitting = 'Submitting',
    Error = 'Error',
    Success = 'Success',
}

interface Props {
    entityID?: string;
    entityType?: BaseEntityType;
    defaultInterval?: StripeInterval;
    onClose: () => void;
}

const PlanModal = ({
    entityID,
    entityType,
    defaultInterval,
    onClose,
}: Props) => {
    // Context hooks
    const toast = useContext(ToastContext);
    const [promoCode, setPromoCode] = useState<string | undefined>(undefined);
    // State hooks
    const [interval, setInterval] = useState<StripeInterval>(
        defaultInterval || 'month'
    );

    const [submitStatus, setSubmitStatus] = useState<SubmitStatus | false>(
        false
    );

    // Constants
    const rookieProduct = RookieProduct.GameDay;

    // API Hooks
    const entityData = useGetEntityQuery(
        // @ts-expect-error
        { entityType, entityID },
        { skip: !entityType || !entityID }
    );

    const { data: licenceTypesRaw, isLoading } = useGetLicenceTypesQuery(
        {
            rookieProduct,
            // @ts-expect-error
            sportID: entityData?.data?.data?.entitySportID,
        },
        { skip: !entityData?.data?.data }
    );

    const { data: licenceGroupsRaw } = useGetLicenceGroupsQuery(
        {
            //@ts-ignore
            entityType,
            //@ts-ignore
            entityID,
        },
        {
            skip: !entityID || !entityType,
        }
    );

    const [createCheckoutSession] = useCreateCheckoutSessionMutation();
    const [updateLicenceGroup] = useUpdateLicenceGroupMutation();
    const licenceGroups = licenceGroupsRaw?.data || [];

    // Flatten all prices within a licenceType and make details more consumable
    const plans = useMemo(() => {
        const licenceTypes = licenceTypesRaw?.data || [];
        let arr: PlanEssentials[] = [];

        if (licenceTypes) {
            licenceTypes.forEach(
                ({
                    product,
                    licenceTypeID,
                    rookieProduct,
                    licenceName,
                }: LicenceType) => {
                    product?.prices.forEach((price) => {
                        arr.push({
                            id: price.priceID,
                            title: licenceName,
                            price: price.unitAmount / 100,
                            currency: 'AUD',
                            interval: price.recurring?.interval,
                            description: product.productDescription,
                            licenceTypeID,
                            rookieProduct,
                        });
                    });
                }
            );
        }
        return arr;
    }, [licenceTypesRaw]);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const purchasePlan = (plan: PlanEssentials) => {
        const priceID = plan.id;
        const returnUrl = window.location.href;

        setSubmitStatus(SubmitStatus.Submitting);

        if (entityType && entityID) {
            createCheckoutSession({
                entityType,
                entityID,
                items: [{ quantity: 1, price: priceID }],
                successURL: returnUrl,
                cancelURL: returnUrl,
                promoCode: promoCode,
            })
                .then((response) => {
                    if ('error' in response) {
                        showToast({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'There was error creating a checkout session. Please try again later.',
                        });
                    } else {
                        // Redirect to stripe checkout
                        window.location.href = response.data.data.url;
                    }
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'There was error creating a checkout session. Please try again later.',
                    });
                })
                .finally(() => {
                    setSubmitStatus(false);
                });
        }
    };

    const updatePlan = (
        licenceGroupID: string,
        priceID: string,
        quantity = 1
    ) => {
        setSubmitStatus(SubmitStatus.Submitting);

        if (entityType && entityID) {
            updateLicenceGroup({
                entityType,
                entityID,
                quantity,
                licenceGroupID,
                priceID,
            })
                .then((response) => {
                    if ('error' in response) {
                        setSubmitStatus(SubmitStatus.Error);
                    } else {
                        setSubmitStatus(SubmitStatus.Success);
                    }
                })
                .catch(() => {
                    setSubmitStatus(SubmitStatus.Error);
                });
        }
    };

    const handlePlanSelect = (plan: PlanEssentials) => {
        const licenceGroupID = licenceGroups.find((group) =>
            plans.find((p) => p.id === group.priceID)
        )?.licenceGroupID;

        const currentPlan = plans.find((plan) =>
            licenceGroups.find((lic) => lic.priceID === plan.id)
        );

        // Show a confirmation modal when updating form an existing plan
        if (currentPlan) {
            confirmDialog({
                message: `Are you sure you want to update your plan from ${currentPlan.title} to ${plan.title}?`,
                header: 'Update Plan',
                accept: () =>
                    licenceGroupID && updatePlan(licenceGroupID, plan.id),
            });
        } else {
            purchasePlan(plan);
        }
    };

    if (submitStatus === SubmitStatus.Submitting) {
        return (
            <div className="plans-submit">
                <Loader size="large" accented weight="heavy" />
                <h2 className="title">Updating Subscription...</h2>
            </div>
        );
    }

    if (submitStatus === SubmitStatus.Success) {
        return (
            <div className="plans-submit">
                <Icon
                    name="check"
                    style={{ fontSize: '80px', color: '#ff6700' }}
                />
                <h2 className="title">Success!</h2>
                <p>Your subscription has been updated!.</p>
                <RookieButton onClick={() => onClose()} label="Close" />
            </div>
        );
    }

    if (submitStatus === SubmitStatus.Error) {
        return (
            <div className="plans-submit">
                <Icon
                    name="warning"
                    style={{ fontSize: '80px', color: 'red' }}
                />
                <h2 className="title">Something went wrong</h2>
                <p>
                    There was error updating your plan. Please try again later.
                </p>
                <RookieButton
                    onClick={() => setSubmitStatus(false)}
                    className="p-button-secondary"
                    label="Try Again"
                />
            </div>
        );
    }

    return (
        <>
            <div className="plans-toolbar">
                <SelectButton
                    allowEmpty={false}
                    value={interval}
                    onChange={(e) => setInterval(e.value)}
                    options={[
                        { label: 'Monthly', value: 'month' },
                        { label: 'Yearly', value: 'year' },
                    ]}
                />
                <InputText
                    id="promoCode"
                    name="promoCode"
                    placeholder="Enter Coupon Code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)} // Update state on change
                />
            </div>

            <div className="plans">
                {isLoading ? (
                    <>
                        <Skeleton
                            width="auto"
                            height="300px"
                            color="#e5e5e5"
                            style={{ flex: '1' }}
                        />
                        <Skeleton
                            width="auto"
                            height="300px"
                            color="#e5e5e5"
                            style={{ flex: '1' }}
                        />
                        <Skeleton
                            width="auto"
                            height="300px"
                            color="#e5e5e5"
                            style={{ flex: '1' }}
                        />
                    </>
                ) : (
                    plans
                        .filter((o) => o.interval === interval)
                        .sort((a, b) => a.price - b.price)
                        .map((plan) => {
                            const isCurrent = licenceGroups.find(
                                (lic) => lic.priceID === plan.id
                            );

                            return (
                                <PlanCard
                                    key={plan.id}
                                    {...plan}
                                    footer={
                                        isCurrent ? (
                                            <RookieButton
                                                severity="secondary"
                                                disabled
                                                label="Your current plan"
                                            />
                                        ) : (
                                            <RookieButton
                                                disabled={!entityID}
                                                onClick={() =>
                                                    handlePlanSelect(plan)
                                                }
                                                label="Select"
                                            />
                                        )
                                    }
                                />
                            );
                        })
                )}
            </div>
        </>
    );
};

export default PlanModal;

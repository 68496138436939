import { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
    useGetOrganisationQuery,
    useDeleteOrganisationMutation,
} from '../../api/organisations';

import { confirmDialog } from 'primereact/confirmdialog';

import OrganisationForm from './OrganisationForm';
import TransferEntityForm from '../users/TransferEntityForm';

import { Sidebar } from 'primereact/sidebar';

import ErrorDisplay from '../../components/ErrorDisplay';
import PageSection, { Annotation } from '../../layout/PageSection';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import Icon from '../../components/Icon';
import { ToastContext } from '../../contexts/ToastContext';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { User } from '../../types/user';
import { Permissions } from '../../types/permissions';
import usePermission from '../../hooks/usePermission';

interface Props {
    user?: User;
}

const OrganisationSettings = (props: Props) => {
    const { organisationID } = useParams();
    const { user } = props;

    const [showTransferModal, setShowTransferModal] = useState(false);
    const [deleteOrganisationMutation] = useDeleteOrganisationMutation();

    const toast = useContext(ToastContext);

    const navigate = useNavigate();

    const { checkPermission, role } = usePermission(organisationID || '');

    // API Calls
    const { data, isLoading, isError } = useGetOrganisationQuery(
        { organisationID },
        {
            skip: !organisationID,
        }
    );
    const deleteOrganisation = () => {
        if (organisationID) {
            deleteOrganisationMutation({ organisationID })
                .then((response) => {
                    navigate(`/u/${user?.userID}/entities/teams`, {
                        replace: false,
                    });

                    toast?.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: `${data?.data.organisationName} has successfully been deleted.`,
                    });
                })
                .catch(() => {
                    toast?.current?.show({
                        severity: 'warn',
                        summary: 'Error',
                        detail: `Sorry, there was an error. Please try again later.`,
                    });
                });
        }
    };

    // Handle form deletion submission result
    const handleDelete = () => {
        confirmDialog({
            message: `This is permanent and cannot be undone. Are you really sure you want to delete ${data?.data.organisationName}?`,
            header: 'Delete Organisation',
            icon: <Icon name="dangerous" />,
            accept: () => deleteOrganisation(),
        });
    };

    // Handle form update submission result
    const handleUpdate = (status: string) => {
        const isSuccess = true;

        toast?.current?.show({
            severity: isSuccess ? 'success' : 'warn',
            summary: isSuccess ? 'Organisation Updated' : 'Update Unsuccessful',
            detail: isSuccess
                ? `The Organisation details have been amended.`
                : `Organisation update could not be completed, try again later.`,
        });
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        command: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc="We were unable to load your Organisation Settings, try again or contact Organisation Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    const isOwner = role.data?.roles.some((role) =>
        role.roleID.includes('Owner')
    );

    return (
        <>
            <PageContainer>
                <PageHeader title="Settings" />
                <PageSection>
                    {permissions.canEdit ? (
                        <>
                            <Annotation title="Club information">
                                <OrganisationForm
                                    organisationID={data?.data?.organisationID}
                                    initialValues={data?.data}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                />
                            </Annotation>
                        </>
                    ) : (
                        <ErrorDisplay
                            errorType={ERROR_TYPES.somethingsWrong}
                            title="You lack permissions to see this page"
                            desc="Contact the page admin if you believe this is in error."
                            proportion="enlarged"
                        />
                    )}

                    {(permissions.canEdit || permissions.canDelete) &&
                        isOwner && (
                            <Annotation
                                title="Danger Zone"
                                description="These actions are permanent and you will not be able to undo."
                            >
                                <div
                                    className="button-group"
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '10px',
                                    }}
                                >
                                    {permissions.canDelete && (
                                        <RookieButton
                                            onClick={() =>
                                                setShowTransferModal(true)
                                            }
                                            label="Transfer Ownership"
                                            severity="danger"
                                        />
                                    )}

                                    {permissions.canDelete && (
                                        <RookieButton
                                            onClick={() => handleDelete()}
                                            label="Delete Organisation"
                                            severity="danger"
                                        />
                                    )}
                                </div>
                            </Annotation>
                        )}
                </PageSection>
            </PageContainer>
            <Sidebar
                header="Transfer Ownership"
                onHide={() => setShowTransferModal(false)}
                visible={showTransferModal}
                position="right"
            >
                {organisationID && (
                    <TransferEntityForm
                        entityType={BaseEntityType.organisations}
                        entityID={organisationID}
                        onSuccess={() => setShowTransferModal(false)}
                    />
                )}
            </Sidebar>
        </>
    );
};

export default OrganisationSettings;

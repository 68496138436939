import { useState } from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType } from '../../types/common';
import { Group, GroupFormData } from '../../types/groups';
import {
    useCreateGroupMutation,
    useUpdateGroupMutation,
} from '../../api/groups';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    group?: Group;
    onError?: (error?: any) => void;
    onCreate?: (response: Group) => void;
    onUpdate?: (response: Group) => void;
}

const GroupsForm = ({
    entityType,
    entityID,
    group,
    onError,
    onCreate,
    onUpdate,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const isUpdating = !!group?.groupID;

    const [createGroup] = useCreateGroupMutation();
    const [updateGroup] = useUpdateGroupMutation();

    const upsertGroup = isUpdating ? updateGroup : createGroup;

    const handleSubmit = (data: GroupFormData) => {
        setLoading(true);

        const args = {
            entityType,
            entityID,
            ...data,
        };

        if (isUpdating && group.groupID) {
            args.groupID = group.groupID;
        }

        if (entityType && entityID) {
            upsertGroup(args)
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onUpdate) {
                            onUpdate(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<GroupFormData> = {};

        if (!values.groupName || values.groupName.trim() === '') {
            errors.groupName = 'Field cannot be blank';
        }

        return errors;
    };

    return (
        <div>
            <Formik
                initialValues={{
                    groupName: group?.groupName || '',
                    groupDescription: group?.groupDescription || '',
                }}
                onSubmit={handleSubmit}
                validate={validate}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormFields
                                style={{ paddingLeft: 0, paddingRight: 0 }}
                            >
                                <FormGroup
                                    label="Name"
                                    htmlFor="groupName"
                                    error={errors.groupName}
                                    showError={
                                        !!errors.groupName && touched.groupName
                                    }
                                >
                                    <InputText
                                        id="groupnName"
                                        name="groupName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Group Name"
                                        value={values.groupName}
                                        autoFocus
                                        disabled={loading}
                                    />
                                </FormGroup>
                                <FormGroup label="Description">
                                    <InputTextarea
                                        id="groupDescription"
                                        name="groupDescription"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Add a description"
                                        value={values.groupDescription}
                                        autoResize
                                        disabled={loading}
                                    />
                                </FormGroup>
                            </FormFields>
                            <FormActions
                                end={
                                    <RookieButton
                                        type="submit"
                                        label={isUpdating ? 'Update' : 'Create'}
                                        disabled={
                                            loading || values.groupName === ''
                                        }
                                        loading={loading}
                                    />
                                }
                            />
                        </form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default GroupsForm;

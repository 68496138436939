import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { BaseEntityType } from '../types/common';
import {
    PresignedOptions,
    PresignedUrlResponse,
    ResourceLinkResponse,
} from '../types/resources';

export const resourcesApi = createApi({
    reducerPath: 'resourcesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    endpoints: (builder) => ({
        createPutPresignedURL: builder.mutation<
            PresignedUrlResponse,
            PresignedOptions & { entityType: BaseEntityType; entityID: string }
        >({
            query: ({ entityType, entityID, ...body }) => ({
                url: generatePath(apiEndpoints.createPutPresignedURL.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createPutPresignedURL.method,
                body,
            }),
        }),
        getSportResourceLinks: builder.query<
            ResourceLinkResponse,
            { sportID: string }
        >({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getSportResourceLinks.url, {
                    sportID,
                }),
                method: apiEndpoints.getSportResourceLinks.method,
            }),
        }),
    }),
});

export const {
    useCreatePutPresignedURLMutation,
    useGetSportResourceLinksQuery,
} = resourcesApi;

import { useContext, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
    useGetTicketQuery,
    useLazyAcceptTicketQuery,
    useLazyDeclineTicketQuery,
} from '../../api/tickets';

import { entityNameMap, loginSourceMap } from '../../util/constants';

import { ToastContext } from '../../contexts/ToastContext';

import { ToastMessage } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { ProgressBar } from 'primereact/progressbar';

import Loader from '../../components/Loader';
import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';
import EntityAvatar from '../../components/EntityAvatar';
import PageContainer from '../../layout/PageContainer';

import { ERROR_TYPES } from '../../types/common';

const TicketContainer = () => {
    const { user: authUser } = useAuth0();

    const toast = useContext(ToastContext);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [searchParams] = useSearchParams();
    const { ticketID, action } = useParams();
    const tokenID = searchParams.get('verify');

    // API hooks
    const { data, isLoading, isError, error } = useGetTicketQuery(
        {
            ticketID: ticketID || '',
            tokenID: tokenID || '',
        },
        {
            skip: !ticketID || !tokenID,
        }
    );
    const [accept] = useLazyAcceptTicketQuery();
    const [decline] = useLazyDeclineTicketQuery();

    const ticket = useMemo(() => data?.data, [data]);

    const title = useMemo(() => {
        if (ticket) {
            const entityNiceName = entityNameMap[ticket.entity.entityType];

            return {
                VerifyIdentity: 'Verify identity',
                AddUserToEntity: `Join your ${entityNiceName}`,
                JoinEvent: 'Join event',
                LinkPlayerToUser: `Join your ${entityNiceName}`,
                EntityTransferOwnership: 'Transfer ownership',
            }[ticket.ticketType];
        }
    }, [ticket]);

    const content = useMemo(() => {
        if (ticket) {
            const entityNiceName = entityNameMap[ticket.entity.entityType];

            return {
                VerifyIdentity: 'Verify Identity',
                AddUserToEntity: (
                    <>
                        <strong>{ticket.actor.name}</strong> has invited you to
                        join the {entityNiceName}{' '}
                        <strong>{ticket?.entity?.name}</strong>
                    </>
                ),
                JoinEvent: (
                    <>
                        <strong>{ticket.actor.name}</strong> has invited you to
                        event <strong>{ticket?.node?.name}</strong>
                    </>
                ),
                LinkPlayerToUser: (
                    <>
                        <strong>{ticket.actor.name}</strong> has invited you to
                        join the {entityNiceName}{' '}
                        <strong>{ticket?.entity?.name}</strong>.
                    </>
                ),
                EntityTransferOwnership: (
                    <>
                        <strong>{ticket.actor.name}</strong> has invited you to
                        claim ownership of the {entityNiceName}{' '}
                        <strong>{ticket?.node?.name}</strong>.
                    </>
                ),
            }[ticket.ticketType];
        }
    }, [ticket]);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleContinue = () => {
        const args = {
            ticketID: ticketID || '',
            tokenID: tokenID || '',
        };

        setIsSubmitting(true);

        if (action === 'accept') {
            accept(args)
                .unwrap()
                .then((fulfilled) => {
                    showToast({
                        severity: 'success',
                        summary: 'Accept Successful',
                        detail: fulfilled.data?.message || '',
                    });
                })
                .catch((rejected) => {
                    showToast({
                        severity: 'error',
                        summary: 'Accept Error',
                        detail: rejected.data?.error || '',
                    });
                })
                .finally(() => {
                    setIsSubmitting(false);

                    return setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                });
        } else if (action === 'decline') {
            decline(args)
                .unwrap()
                .then((fulfilled) => {
                    showToast({
                        severity: 'success',
                        summary: 'Decline Successful',
                        detail: fulfilled.data?.message || '',
                    });
                })
                .catch((rejected) => {
                    showToast({
                        severity: 'error',
                        summary: 'Decline Error',
                        detail: rejected.data?.error || '',
                    });
                })
                .finally(() => {
                    setIsSubmitting(false);

                    return setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                });
        } else {
            showToast({
                severity: 'error',
                summary: 'There was an error',
                detail: 'An action for this ticket was not provided',
            });
            setIsSubmitting(false);
            return (window.location.href = '/');
        }
    };

    const handleCancel = () => {
        return (window.location.href = '/');
    };

    if (isError) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.somethingsWrong}
                desc={
                    // @ts-expect-error
                    error?.data?.error ||
                    'There was an error retrieving your ticket.'
                }
                title="An error has occurred"
                proportion="enlarged"
                hasReturn={false}
            />
        );
    }

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    return (
        <PageContainer>
            {authUser && authUser.email !== ticket?.metadata.target && (
                <Message
                    text={
                        <>
                            <strong>
                                This notification is intended for{' '}
                                {ticket?.metadata.target}
                            </strong>{' '}
                            and you are currently logged in as {authUser.email}
                            {authUser.sub
                                ? ' via ' +
                                  loginSourceMap[authUser.sub.split('|')[0]]
                                : ''}
                            . If this is your other account, please logout and
                            sign into that account. If this is not you, please
                            disregard this message and it will expire on it's
                            own.
                        </>
                    }
                />
            )}
            <Dialog
                appendTo="self"
                className="ticket-message"
                maskClassName="ticket-message-mask"
                visible={true}
                onHide={() => {}}
                draggable={false}
                closable={false}
                header={
                    <div>
                        {ticket && ticket.ticketType !== 'VerifyIdentity' && (
                            <EntityAvatar
                                entityID={ticket.entity.entityID}
                                entityType={ticket.entity.entityType}
                                entityName={ticket.entity.name}
                            />
                        )}
                        <div>{title}</div>
                    </div>
                }
                footer={
                    <div className="button-set">
                        <RookieButton
                            label="Cancel"
                            severity="secondary"
                            onClick={handleCancel}
                            disabled={isSubmitting}
                        />
                        <RookieButton
                            label="Continue"
                            onClick={handleContinue}
                            disabled={isSubmitting}
                        />
                    </div>
                }
            >
                <div>{content}</div>

                {isSubmitting && (
                    <div className="form-overlay">
                        <ProgressBar mode="indeterminate" />
                    </div>
                )}
            </Dialog>
        </PageContainer>
    );
};

export default TicketContainer;

import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { unionBy, orderBy, sortBy } from 'lodash';

import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../api/seasons';
import { useLazyGetPlayersQuery } from '../../api/players';
import { useGetEventsQuery } from '../../api/events';

import {
    useGetTeamSeasonTimeBasicReportQuery,
    useGetTeamSeasonSummaryReportQuery,
} from '../../api/reports';

import PageHeader from '../../layout/PageHeader';
import playLogo from '../../assets/images/logos/rm-play-logo.png';
import { Image } from 'primereact/image';

import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { Toolbar } from 'primereact/toolbar';

import { defaultReportState } from '../reports/constants';
import { playerGroupCell, playerNameCell } from '../reports/DataTableCells';

import { ReportState, TimeStat } from '../../types/reports';
import ErrorDisplay from '../../components/ErrorDisplay';
import { ERROR_TYPES } from '../../types/common';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { Mixpanel } from '../../util/mixpanel';
import { calculateTotals } from './helpers';
import { useGetTeamQuery } from '../../api/teams';
import { toISOStringWithTimezone } from '../../util/helper';
import { BaseEntityType } from '../../types/common';
import { Role } from '../../types/roles';
import { Me } from '../../types/user';
import { Player } from '../../types/team';

interface Props {
    reportType: 'basic';
    user: Me;
}

const PlayerVotesReport = ({ reportType, user }: Props) => {
    // Route Params
    const { teamID, associationID } = useParams();

    // State Hooks
    const [season, setSeason] = useState<string | null>(null);
    const [event] = useState<string>('');
    const [reportData, setReportData] =
        useState<ReportState<TimeStat>>(defaultReportState);
    const [, setSummaryReportData] =
        useState<ReportState<TimeStat>>(defaultReportState);
    const [categoryValue, setCategoryValue] = useState<string>('Best Player');
    const [players, setPlayers] = useState<Player[]>([]);
    const [eventNameData, setEventNameData] = useState<string[]>([]);

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;
    const dataTable = useRef<DataTable<any>>(null);

    const reportParams = {
        seasonID: season || '',
        teamID: teamID || '',
        sessionID: timestampRef,
    };

    // API Hooks
    const [
        fetchPlayers,
        { originalArgs: playerArgs, isLoading: isLoadingPlayers },
    ] = useLazyGetPlayersQuery();

    const { data: teamData } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const requestTeamSummaryReport = useGetTeamSeasonSummaryReportQuery(
        { ...reportParams, concatenated: true },
        {
            skip: !season || !teamID,
        }
    );

    const requestBasicReport = useGetTeamSeasonTimeBasicReportQuery(
        reportParams,
        {
            skip: !season || !teamID || reportType === 'basic',
        }
    );

    const requestSummaryData = requestTeamSummaryReport;
    const requestReportData = requestBasicReport;

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
        expand: 'gameDetails',
    });

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Helpers
    const currentEntityRoles = (user?.roles?.filter(
        (role: Role) => role.entityID === teamID
    ) ?? []) as Role[];

    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Check if team owner and also not an assosication
    let canView = true;
    if (
        associationID ||
        !currentEntityRoles[0]?.permissions?.includes('OWNER_ROLE')
    ) {
        canView = false;
    }

    // Fetch report Time Report
    useEffect(() => {
        const reportUrl = requestReportData?.data?.data.objectURL;

        if (reportUrl && requestReportData.isSuccess) {
            setReportData({
                ...defaultReportState,
                isLoading: true,
                isUninitialized: false,
            });

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setReportData(defaultReportState);
        }
    }, [requestReportData]);

    // Fetch report Summary Report
    useEffect(() => {
        const reportUrl = requestSummaryData?.data?.data.objectURL;

        if (reportUrl && requestSummaryData.isSuccess) {
            setSummaryReportData({
                ...defaultReportState,
                isLoading: true,
                isUninitialized: false,
            });

            //fetch report
            fetch(reportUrl)
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setSummaryReportData((prev) => ({
                        ...prev,
                        data,
                        isError: false,
                        isLoading: false,
                    }));
                })
                .catch((err) => {
                    setSummaryReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }));
                });
        } else {
            setSummaryReportData(defaultReportState);
        }
    }, [requestSummaryData]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(teamData?.data.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, teamData]);

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            !reportData.isUninitialized &&
            !reportData.isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData.data?.some(
                (stat: any) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, playerArgs]);

    useEffect(() => {
        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: any) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, reportData.isLoading]);

    const seasonOptions = useMemo(() => {
        let options = mergedSeasons
            ? mergedSeasons.map((season) => ({
                  label: season.seasonName,
                  value: season.seasonID,
              }))
            : [];

        return options;
    }, [mergedSeasons]);

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Cumulative Bench Starts`,
        });
    };

    // Renders
    const leftToolbar = (
        <>
            <div className="p-button-group">
                <Dropdown
                    onChange={(e) => setSeason(e.value)}
                    value={season}
                    options={seasonOptions}
                />
                <Dropdown
                    value={categoryValue}
                    onChange={(e) => setCategoryValue(e.value)}
                    options={[
                        {
                            label: 'Best Player',
                        },
                        { label: 'Best & Fairest' },
                        { label: 'Performance Rating' },
                    ]}
                />
            </div>
        </>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <RookieButton
                type="button"
                onClick={() => exportCSV()}
                label="Export CSV"
                severity="secondary"
            />
        </div>
    );

    const renderEmpty = () => {
        const msg = event
            ? "Can't load report for this event. The data may be corrupt. Try rebuilding your report or reach out to us via the question mark at the base of the page."
            : 'Unable to find report. Please try again later';
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ErrorDisplay
                    alignment="middle"
                    title="No Report Found"
                    desc={msg}
                    errorType={errorType}
                    hasReturn={false}
                    proportion="compact"
                />
            </div>
        );
    };

    const filteredData: TimeStat[] = useMemo(() => {
        // Assuming reportData.data is either an array of TimeStat objects or undefined
        let filtered = (reportData.data || []) as TimeStat[];

        const brokenEvents = filtered
            .filter((item: TimeStat) => item.timePos__Field > 0)
            .map((item: TimeStat) => item.gameID);

        filtered = filtered.filter(
            (item: TimeStat) => !brokenEvents.includes(item.gameID)
        );

        // Apply category filter
        if (event !== '') {
            filtered = filtered.filter(
                (item: TimeStat) => item.gameID === event
            );
        }

        const sorted = sortBy(
            filtered,
            (item: TimeStat) => item.player && Number(item.player.uniformNumber)
        );

        return sorted;
    }, [reportData, event]); // Recalculate when reportData or event changes

    const calculatedData = useMemo(() => {
        let calculated = calculateTotals(filteredData, 'playerID');

        return calculated;
    }, [filteredData]);

    // Get unique event IDs from the data
    const uniqueGameIDs: string[] = Array.from(
        new Set(filteredData.map((item: TimeStat) => item.gameID))
    );

    const getEventDisplayNames = (
        uniqueGameIDs: string[],
        eventIDData: { eventID: string; eventName: string }[] = []
    ) => {
        return uniqueGameIDs.map((eventID) => {
            const player = eventIDData.find((p) => p.eventID === eventID);
            return player ? player.eventName : 'Unknown Event';
        });
    };
    // Update eventData state after fetching player names
    useEffect(() => {
        if (reportData.data && eventData.data) {
            const eventIDData = eventData.data.data || [];
            const eventNames = getEventDisplayNames(uniqueGameIDs, eventIDData);

            setEventNameData(eventNames); // Directly set the array of strings
        }
    }, [reportData, eventData, uniqueGameIDs]);

    // Define columns dynamically based on uniqueGameIDs
    const gameIDColumns = eventNameData.map((name) => (
        <Column
            key={name}
            header={name}
            headerStyle={{ backgroundColor: 'white' }}
        />
    ));

    const calculatePlayedCount = (row: TimeStat) => {
        let value = null;
        if (reportData && reportData.data) {
            value = reportData.data.filter(
                (o: TimeStat) => o.playerID === row.playerID && o.period === 1
            ).length;
        }

        return value;
    };

    const sortByGamesPlayed = ({ data, order }: ColumnSortEvent) =>
        order &&
        orderBy(
            data,
            (o) => calculatePlayedCount(o),
            order > 0 ? 'asc' : 'desc'
        );

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader title="Player Voting / Rating" />
            {!canView ? (
                <div>
                    You do not have permission to view this data. You must be a
                    team owner.
                </div>
            ) : eventData && eventData.data ? ( // Ensure eventData exists before checking eventData.data
                <>
                    <Toolbar start={leftToolbar} end={rightToolbar} />

                    <DataTable
                        ref={dataTable}
                        removableSort
                        showGridlines
                        value={
                            reportData.isLoading || reportData.isUninitialized
                                ? Array(5)
                                : calculatedData
                        }
                        emptyMessage={renderEmpty()}
                        scrollable
                        scrollHeight="100vh"
                        columnResizeMode="expand"
                        resizableColumns
                        footer={tableFooter}
                        exportFilename={`season-bench-start-report`}
                        stripedRows={true}
                        // @ts-expect-error
                        hideInjuryTooltip
                    >
                        <Column
                            header="#"
                            align="center"
                            alignHeader="center"
                            sortable
                            field="player.uniformNumber"
                            frozen
                            headerStyle={{ backgroundColor: 'white' }}
                        />
                        <Column
                            frozen
                            sortable
                            header="Player"
                            field="player.lastName"
                            body={
                                isLoadingPlayers ? <Skeleton /> : playerNameCell
                            }
                            exportable={false}
                            headerStyle={{ backgroundColor: 'white' }}
                        />
                        <Column
                            header="First Name"
                            field="player.firstName"
                            hidden={true}
                        />
                        <Column
                            header="Last Name"
                            field="player.lastName"
                            hidden={true}
                        />
                        <Column
                            sortable
                            header="Group"
                            field="player.colour"
                            body={
                                isLoadingPlayers ? (
                                    <Skeleton />
                                ) : (
                                    playerGroupCell
                                )
                            }
                            headerStyle={{ backgroundColor: 'white' }}
                        />
                        {event === '' && (
                            <Column
                                align="center"
                                alignHeader="center"
                                sortable
                                sortFunction={sortByGamesPlayed}
                                body={(row) =>
                                    !row || reportData.isLoading ? (
                                        <Skeleton />
                                    ) : (
                                        calculatePlayedCount(row)
                                    )
                                }
                                header="Games"
                                headerStyle={{ backgroundColor: 'white' }}
                            />
                        )}
                        {gameIDColumns}
                        <Column sortable header="Total" />
                    </DataTable>
                </>
            ) : (
                <div>No player data available</div>
            )}
        </PageContainer>
    );
};

export default PlayerVotesReport;

import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ToastContext } from '../../contexts/ToastContext';
import usePermission from '../../hooks/usePermission';

import GroupSingleView from './GroupSingleView';

import { getEntityFromParam } from '../../util/helper';

import DocumentHead from '../../components/DocumentHead';
import Icon from '../../components/Icon';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import { Permissions } from '../../types/permissions';
import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import {
    useDeleteGroupMutation,
    useGetGroupQuery,
    useGetGroupUsersQuery,
    useRemoveUserFromGroupMutation,
} from '../../api/groups';

interface Props {
    roles: Roles;
    route: Route;
}

const GroupSingleContainer = (props: Props) => {
    const params = useParams();
    const { groupID } = params;
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);
    const [cursor, setCursor] = useState('');

    const [editing, setEditing] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const toast = useContext(ToastContext);

    const { data: groupData } = useGetGroupQuery(
        // @ts-expect-error
        { ...activeEntity, groupID },
        {
            skip: !activeEntity,
        }
    );

    const { data, isLoading, isError, isFetching } = useGetGroupUsersQuery(
        // @ts-expect-error
        { ...activeEntity, groupID, cursor },
        {
            skip: !activeEntity,
        }
    );

    const groupUsers = useMemo(() => data?.data, [data]);

    const [deleteGroup] = useDeleteGroupMutation();
    const [removeUser] = useRemoveUserFromGroupMutation();

    // Permission Hook
    const { checkPermission } = usePermission(activeEntity?.entityID || '');

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && data.lastEvaluatedKey.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };

    const handleEditGroup = () => {
        setEditing(true);
    };

    const handleRemoveUser = (userID: string) => {
        if (activeEntity && groupID && userID) {
            confirmDialog({
                message: `Are you sure you want to remove this user from your group?`,
                header: 'Remove User',
                icon: <Icon name="dangerous" />,
                accept: () =>
                    removeUser({
                        ...activeEntity,
                        groupID,
                        userID,
                    })
                        .then((response) => {
                            if ('error' in response) {
                                showToast({
                                    severity: 'error',
                                    detail: 'There was an error removing the user. Please try again.',
                                    summary: 'Error',
                                });
                            }
                        })
                        .catch(() => {
                            showToast({
                                severity: 'success',
                                detail: 'Successfully removed user.',
                            });
                        }),
            });
        }
    };

    const handleDeleteGroup = (groupID: string) => {
        if (activeEntity) {
            confirmDialog({
                message: `Are you sure you want to delete this group?`,
                header: 'Delete Group',
                icon: <Icon name="dangerous" />,
                accept: () =>
                    deleteGroup({ ...activeEntity, groupID })
                        .then((response) => {
                            if ('error' in response) {
                                showToast({
                                    severity: 'error',
                                    detail: 'There was an error deleting the group. Please try again.',
                                    summary: 'Error',
                                });
                            } else {
                                showToast({
                                    severity: 'success',
                                    detail: 'Successfully updated group.',
                                });
                            }
                        })
                        .catch((err) => {
                            showToast({
                                severity: 'error',
                                detail: err.error,
                                summary: 'Error',
                            });
                        }),
            });
        }
    };

    const handleCloseModal = () => {
        setEditing(false);
    };

    const permissions = {
        canCreate: checkPermission([Permissions.MANAGE_TEAMS_ALL]),
        canDelete: checkPermission([Permissions.MANAGE_TEAMS_ALL]),
        canEdit: checkPermission([Permissions.MANAGE_TEAMS_ALL]),
        canView: checkPermission([
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Groups - Rookie Me Hub"
                description="Group page"
            />
            <GroupSingleView
                entityType={activeEntity?.entityType}
                entityID={activeEntity?.entityID}
                groupID={groupID}
                group={groupData?.data}
                groupUsers={groupUsers}
                editing={editing}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                onEdit={handleEditGroup}
                onDelete={handleDeleteGroup}
                onCloseEditModal={handleCloseModal}
                onLoadMore={handleLoadMore}
                onRemoveUser={handleRemoveUser}
                permissions={permissions}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                showAddModal={showAddModal}
                onToggleAddModal={() => setShowAddModal((prev) => !prev)}
                {...props}
            />
        </>
    );
};

export default GroupSingleContainer;

import { useNavigate } from 'react-router-dom';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import Loader from '../../components/Loader';
import { DataTable } from 'primereact/datatable';
import RookieButton from '../../components/RookieButton';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import GroupsForm from './GroupsForm';
import { Group } from '../../types/groups';
import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { BaseEntityType } from '../../types/common';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    data: Group[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onLoadMore?: () => void;
    onDelete?: (groupID: string) => void;
    onEdit?: (group: Group) => void;
    roles: Roles;
    showPagination: boolean;
    onCloseCreateModal: () => void;
    onOpenCreateModal: () => void;
    showFormModal: boolean;
    editing: Group | null;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const UserGroupsView = (props: Props) => {
    const navigate = useNavigate();

    return (
        <PageContainer>
            <PageHeader
                title="Groups"
                actions={
                    props.permissions.canCreate
                        ? [
                              {
                                  label: 'Create Group',
                                  command: props.onOpenCreateModal,
                              },
                          ]
                        : []
                }
            />
            {props.isLoading ? (
                <Loader />
            ) : (
                <DataTable
                    value={props.data}
                    onRowClick={(row) => navigate(row.data.groupID)}
                    selectionMode="single"
                    footer={
                        props.showPagination &&
                        props.onLoadMore && (
                            <RookieButton
                                label="Load More"
                                onClick={props.onLoadMore}
                                size="large"
                                className="load-more"
                                severity="secondary"
                                loading={props.isFetching}
                            />
                        )
                    }
                >
                    <Column header="Name" field="groupName" />
                    <Column header="Description" field="groupDescription" />
                </DataTable>
            )}
            {props.entityType && props.entityID && (
                <Dialog
                    onHide={props.onCloseCreateModal}
                    visible={props.showFormModal}
                    header={props.editing ? 'Edit Group' : 'Create Group'}
                    resizable={false}
                    draggable={false}
                >
                    <GroupsForm
                        group={props.editing || undefined}
                        entityType={props.entityType}
                        entityID={props.entityID}
                        onUpdate={() => props.onCloseCreateModal()}
                        onCreate={() => props.onCloseCreateModal()}
                        onError={() => props.onCloseCreateModal()}
                    />
                </Dialog>
            )}
        </PageContainer>
    );
};

export default UserGroupsView;

import { useContext, useRef, useState } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { format } from 'date-fns';

import PlayerPane from './PlayerPane';
import PlayerForm from './PlayerForm';

import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';
import SpreadsheetImport from '../../components/SpreadsheetImport';
import PageHeader from '../../layout/PageHeader';

import { Avatar } from 'primereact/avatar';
import { Column, ColumnProps } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Skeleton } from 'primereact/skeleton';
import { useGetTeamQuery } from '../../api/teams';

import { PLAYER_FIELD } from '../../util/importFields';
import { getFirstChars } from '../../util/helper';

import { Action, ERROR_TYPES } from '../../types/common';
import { Player } from '../../types/team';
import { Roles } from '../../types/roles';

import PageContainer from '../../layout/PageContainer';
import { Sidebar } from 'primereact/sidebar';

import { Mixpanel } from '../../util/mixpanel';
import { TabMenu } from 'primereact/tabmenu';
import { ToastContext } from '../../contexts/ToastContext';
import { UserDetails } from '../../types/user';

const STATUS_SUCCESS = 'success';
const STATUS_UPDATED = 'updated';

interface Props {
    data: Player[];
    staffData: UserDetails[];
    focusedUser: Player | null;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    isImporting: boolean;
    onClickUser: (playerID: string) => void;
    onCloseDeleteDialog: () => void;
    onCloseDrawer: () => void;
    onCloseInviteDialog: () => void;
    onDeleteUser: (playerID: string) => void;
    onFocusUser: (player: Player) => void;
    onLoadMore?: () => void;
    onShowDeleteDialog: () => void;
    onTabChange?: () => void;
    onViewUser: (playerID: string) => void;
    onImport: (data: any) => void;
    onShowInviteDialog: Function;
    roles: Roles;
    showDeleteDialog: boolean;
    showPagination: boolean;
    viewMedia?: string;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const PlayersView = (props: Props) => {
    const {
        focusedUser,
        isError,
        isFetching,
        isLoading,
        isImporting,
        onImport,
        staffData,
    } = props;
    const navigate = useNavigate();
    const { teamID } = useParams();
    const rowMenu = useRef<Menu>(null);
    const rowContextMenu = useRef<ContextMenu>(null);
    const dataTable = useRef<any>(null);
    const toast = useContext(ToastContext);

    const [isSpreadsheetModalOpen, setSpreadsheetModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get('tab');

    const focusedUserID = focusedUser?.playerID;

    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === STATUS_SUCCESS;
        const hasUpdated = submitResult === STATUS_UPDATED;

        if (props.onCloseDrawer) {
            props.onCloseDrawer();
        }

        if (toast && toast.current) {
            toast.current.show({
                severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
                summary: hasUpdated
                    ? 'Player Updated'
                    : hasSubmitted
                    ? 'Player Added'
                    : 'Player Addition Unsuccessful',
            });
        }
    };

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Players',
        });
    };

    const titleTemplate = (row: Player) => {
        return (
            <div className="detail-cell">
                <Avatar
                    label={getFirstChars(row.displayName, 2).toUpperCase()}
                    image={row.playerImageURL}
                    style={{ background: '#4A4A4D', color: '#ffffff' }}
                    shape="circle"
                />
                <span>
                    {row.firstName} {row.lastName}
                </span>
            </div>
        );
    };

    const actionTemplate = (row: Player) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        props.onFocusUser(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    const renderGroup = (row: Player) => {
        return (
            <Avatar
                style={{
                    background: row && row.colour ? row.colour : 'transparent',
                    width: '24px',
                    height: '16px',
                    border: '1px solid #e5e5e5',
                }}
            />
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'uniformNumber',
            header: '#',
            style: { width: '60px' },
            align: 'center',
            sortable: true,
            alignHeader: 'right',
        },
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'firstName',
            header: 'Name',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'gender',
            header: 'Gender',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'dateOfBirth',
            header: 'DOB',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            body: (row: Player) =>
                isLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'yyyy-MM-dd')
                ),
            field: 'createdAt',
            header: 'Created',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            field: 'createdBy',
            header: 'Created By',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
            body: (row) => {
                const staff = staffData.find(
                    (staff) => staff.userID === row.createdBy
                );

                return staff ? `${staff?.firstName} ${staff?.lastName}` : '';
            },
        },
        {
            field: 'player.colour',
            header: 'Group',
            sortable: true,
            body: renderGroup,
            style: { width: '100px' },
            align: 'center',
            alignHeader: 'center',
            exportField: 'colour',
        },
        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
            exportable: false,
        },
        /**** Export Fields ****/
        {
            field: 'firstName',
            header: 'First Name',
            hidden: true,
        },
        {
            field: 'lastName',
            header: 'Last Name',
            hidden: true,
        },
        {
            field: 'displayName',
            header: 'Display Name',
            hidden: true,
        },
        {
            field: 'dateOfBirth',
            header: 'DOB',
            hidden: true,
        },
        {
            field: 'gender',
            header: 'Gender',
            hidden: true,
        },
        {
            field: 'uniformNumber',
            header: 'Uniform Number',
            hidden: true,
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    // empty array to populate rows for skeleton loading components
    const blankRows = Array(5);

    const pageHeaderActions: Action[] =
        props.permissions.canCreate &&
        teamData?.data &&
        teamData?.data?.data.teamStatus !== 'Archived'
            ? [
                  {
                      key: 'export',
                      label: 'Export',
                      severity: 'secondary',
                      type: 'button',
                      command: () => exportCSV(),
                  },
                  {
                      key: 'import',
                      label: 'Import Players',
                      severity: 'secondary',
                      type: 'button',
                      command: () => setSpreadsheetModalOpen(true),
                  },
                  {
                      key: 'create',
                      label: 'Add Player',
                      icon: 'add',
                      type: 'button',
                      command: () => props.onShowInviteDialog(true),
                  },
              ]
            : [];

    const playerMenuActions: MenuItem[] = [
        ...(props.permissions.canEdit
            ? [
                  {
                      label: 'Edit',
                      command: () =>
                          focusedUserID && props.onShowInviteDialog(false),
                  },
              ]
            : []),
        ...(props.permissions.canDelete
            ? [
                  {
                      label: 'Delete',
                      command: () => props.onShowDeleteDialog(),
                  },
              ]
            : []),
    ];

    const deleteModalContent = focusedUser && (
        <>
            <p className="delete-msg">
                Are you sure you want to remove this player?
            </p>
            <div className="list">
                <ListItem
                    start={
                        <Avatar
                            image={focusedUser.playerImageURL}
                            label={
                                focusedUser.firstName +
                                ' ' +
                                focusedUser.lastName
                            }
                        />
                    }
                    caption={focusedUser.displayName}
                    title={focusedUser.firstName}
                />
            </div>

            <div className="form-actions">
                <RookieButton
                    text={true}
                    severity="secondary"
                    onClick={() => props.onCloseDeleteDialog()}
                    type="button"
                    icon="cancel"
                    label="Cancel"
                />
                <RookieButton
                    severity="danger"
                    type="submit"
                    onClick={() =>
                        focusedUserID && props.onDeleteUser(focusedUserID)
                    }
                    icon="delete"
                    label="Delete Player"
                />
            </div>
        </>
    );

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              command: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : [
                          {
                              label: 'Add Player',
                              icon: 'add',
                              type: 'button',
                              command: () => props.onShowInviteDialog(true),
                          },
                      ]
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No players found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Player' in the top right area."
            }
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={props.onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    const getPlayerModalTitle = () => {
        if (searchParams.has('create')) {
            return focusedUser?.playerID ? 'Edit Player' : 'Add Player';
        }

        return '';
    };

    const tabs = [
        ...(teamData.data?.data.teamStatus !== 'Archived'
            ? [
                  {
                      id: 'Active',
                      label: 'Active',
                  },
              ]
            : []),
        {
            id: 'Archived',
            label: 'Archived',
        },
    ];

    const activeTabIndex = tabs.findIndex((tab) => activeTab === tab.id);

    return (
        <>
            <PageContainer>
                <PageHeader title="Players" actions={pageHeaderActions} />

                <TabMenu
                    model={tabs}
                    activeIndex={activeTabIndex}
                    onTabChange={(e) => {
                        if (props.onTabChange) {
                            props.onTabChange();
                        }

                        setSearchParams({ tab: tabs[e.index].id });
                    }}
                />
                {props.isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <DataTable
                        ref={dataTable}
                        value={
                            isLoading
                                ? blankRows
                                : sortBy(
                                      props.data,
                                      (player) =>
                                          player.uniformNumber &&
                                          Number(player.uniformNumber)
                                  )
                        }
                        loading={(isImporting || isFetching) && !isLoading}
                        footer={
                            !isFetching && props.showPagination
                                ? footerTemplate
                                : null
                        }
                        emptyMessage={tableEmptyMessage}
                        selectionMode="single"
                        onSelectionChange={(e) => {
                            props.onClickUser(e.value.playerID);
                            props.onFocusUser(e.value);
                        }}
                        onContextMenuSelectionChange={(e) =>
                            props.onFocusUser(e.value)
                        }
                        onContextMenu={(e) => {
                            if (rowContextMenu.current) {
                                rowContextMenu.current.show(e.originalEvent);
                            }
                            if (rowMenu.current) {
                                rowMenu.current.hide(e.originalEvent);
                            }
                        }}
                        columnResizeMode="expand"
                        resizableColumns
                        exportFilename="players-export"
                    >
                        {isLoading
                            ? columns
                            : props.data.length > 0
                            ? columns
                            : null}
                    </DataTable>
                )}
            </PageContainer>

            <Menu model={playerMenuActions} popup ref={rowMenu} />
            <ContextMenu model={playerMenuActions} ref={rowContextMenu} />

            <Dialog
                className="delete-modal is-form"
                onHide={props.onCloseDeleteDialog}
                header="Delete Player"
                visible={props.showDeleteDialog}
            >
                {focusedUser && deleteModalContent}
            </Dialog>

            <Sidebar
                position="right"
                className="is-form"
                visible={searchParams.has('create') || searchParams.has('id')}
                header={getPlayerModalTitle()}
                onHide={props.onCloseDrawer}
                icons={
                    <>
                        <RookieButton
                            text
                            icon="edit"
                            onClick={() => {
                                focusedUserID &&
                                    props.onShowInviteDialog(false);
                            }}
                        />
                        <RookieButton
                            text
                            icon="delete"
                            onClick={() => {
                                props.onShowDeleteDialog();
                                props.onCloseDrawer();
                            }}
                        />
                    </>
                }
            >
                {searchParams.get('create') ? (
                    <PlayerForm
                        onCancel={props.onCloseInviteDialog}
                        onSubmit={handleSubmitResult}
                        playerID={focusedUser?.playerID}
                        initialValues={focusedUser ? focusedUser : undefined}
                    />
                ) : searchParams.get('id') ? (
                    <PlayerPane player={searchParams.get('id') || ''} />
                ) : (
                    <div>Loading...</div>
                )}
            </Sidebar>

            {isSpreadsheetModalOpen && (
                <SpreadsheetImport
                    isOpen={isSpreadsheetModalOpen}
                    onClose={() => setSpreadsheetModalOpen(false)}
                    onSubmit={onImport}
                    fields={PLAYER_FIELD}
                />
            )}
        </>
    );
};

export default PlayersView;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';
import { apiEndpoints, prepareHeaders } from './apiEndpoints';
import { EntityStructure } from '../types/common';
import {
    GroupFormData,
    GroupResponse,
    GroupsResponse,
    GroupUserInput,
    GroupUsersResponse,
} from '../types/groups';

export const groupsApi = createApi({
    reducerPath: 'groupsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders,
    }),
    tagTypes: ['Group', 'GroupUser'],
    endpoints: (builder) => ({
        getGroups: builder.query<GroupsResponse, EntityStructure>({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getGroups.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getGroups.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ groupID }) => ({
                              type: 'Group' as const,
                              groupID,
                          })),
                          'Group',
                      ]
                    : ['Group'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getGroup: builder.query<
            GroupResponse,
            EntityStructure & {
                groupID: string;
            }
        >({
            query: ({ entityType, entityID, groupID }) => ({
                url: generatePath(apiEndpoints.getGroup.url, {
                    entityType,
                    entityID,
                    groupID,
                }),
                method: apiEndpoints.getGroup.method,
            }),
        }),
        createGroup: builder.mutation<
            GroupResponse,
            GroupFormData & EntityStructure
        >({
            query: ({ entityType, entityID, ...data }) => ({
                url: generatePath(apiEndpoints.createGroup.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createGroup.method,
                body: data,
            }),
            invalidatesTags: ['Group'],
        }),
        updateGroup: builder.mutation<
            GroupResponse,
            GroupFormData & EntityStructure
        >({
            query: ({ entityType, entityID, groupID, ...data }) => ({
                url: generatePath(apiEndpoints.updateGroup.url, {
                    entityType,
                    entityID,
                    groupID,
                }),
                method: apiEndpoints.updateGroup.method,
                body: data,
            }),
            invalidatesTags: ['Group'],
        }),
        deleteGroup: builder.mutation<
            void,
            EntityStructure & { groupID: string }
        >({
            query: ({ entityType, entityID, groupID }) => ({
                url: generatePath(apiEndpoints.deleteGroup.url, {
                    entityType,
                    entityID,
                    groupID,
                }),
                method: apiEndpoints.deleteGroup.method,
            }),
            invalidatesTags: ['Group'],
        }),
        getGroupUsers: builder.query<
            GroupUsersResponse,
            EntityStructure & { groupID: string; cursor: string }
        >({
            query: ({ entityType, entityID, groupID, ...params }) => ({
                url: generatePath(apiEndpoints.getGroupUsers.url, {
                    entityType,
                    entityID,
                    groupID,
                }),
                method: apiEndpoints.getGroupUsers.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'GroupUser' as const,
                              userID,
                          })),
                          'GroupUser',
                      ]
                    : ['GroupUser'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        addUserToGroup: builder.mutation<
            void,
            EntityStructure & { groupID: string; users: GroupUserInput[] }
        >({
            query: ({ entityType, entityID, groupID, users }) => ({
                url: generatePath(apiEndpoints.addUserToGroup.url, {
                    entityType,
                    entityID,
                    groupID,
                }),
                method: apiEndpoints.addUserToGroup.method,
                body: users,
            }),
            invalidatesTags: ['GroupUser'],
        }),
        removeUserFromGroup: builder.mutation<
            void,
            EntityStructure & { groupID: string; userID: string }
        >({
            query: ({ entityType, entityID, groupID, userID }) => ({
                url: generatePath(apiEndpoints.deleteUserFromGroup.url, {
                    entityType,
                    entityID,
                    groupID,
                    userID,
                }),
                method: apiEndpoints.deleteUserFromGroup.method,
            }),
            invalidatesTags: ['GroupUser'],
        }),
    }),
});

export const {
    useGetGroupsQuery,
    useGetGroupQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useGetGroupUsersQuery,
    useAddUserToGroupMutation,
    useRemoveUserFromGroupMutation,
} = groupsApi;

import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isArray, orderBy } from 'lodash';
import { ProgressBar } from 'primereact/progressbar';

import { useGetOrganisationTeamsQuery } from '../../api/organisations';
import { useLazyGetTeamSeasonSummaryReportQuery } from '../../api/reports';
import { useLazyGetTeamParticipatingSeasonsQuery } from '../../api/seasons';

import { columns, config } from './configSummary';

import playLogo from '../../assets/images/logos/rm-play-logo.png';

import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';

import { Column, ColumnSortEvent } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectButton } from 'primereact/selectbutton';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';

import { ReportDataTypes } from '../../types/reports';
import { Season } from '../../types/seasons';
import { StatConfig, SeasonGameSummary } from '../../types/reports';
import { Team } from '../../types/team';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface ReportData {
    [key: string]: {
        isLoading: boolean;
        isError: boolean;
        isInitialized: boolean;
        events?: any[];
    };
}

interface ReportState {
    [key: string]: {
        team: Team;
        seasons: ReportData;
    };
}

const OrgTeamSummaryReport = () => {
    const { organisationID } = useParams();

    const timestampRef = useRef(Date.now()).current;

    const [teamReports, setTeamReports] = useState<ReportState>({});
    const [seasonData, setSeasonData] = useState<{ [key: string]: Season[] }>(
        {}
    );
    const [activeSeason, setActiveSeason] = useState<{ [key: string]: string }>(
        {}
    );
    const [showInfo, setShowInfo] = useState(false);
    const [hideEmptyCol, setHideEmptyCol] = useState<boolean>(true);
    const [hideIntFGTScore, sethideIntFGTScore] = useState(false);
    const [dataType, setDataType] = useState(ReportDataTypes.Total);

    // API Hooks
    const teamsData = useGetOrganisationTeamsQuery(
        { organisationID: organisationID },
        { skip: !organisationID }
    );

    const [fetchTeamSeasonReport] = useLazyGetTeamSeasonSummaryReportQuery();
    const [fetchTeamParticipatingSeasons] =
        useLazyGetTeamParticipatingSeasonsQuery();

    const teams = useMemo(() => teamsData.data?.data || [], [teamsData]);

    // Set default season as team as active season
    useEffect(() => {
        for (const team of teams) {
            // Set default active season for each team to team's default season ID
            setActiveSeason((prev) => ({
                ...prev,
                [team.teamID]: team.defaultSeasonID,
            }));

            // Set team to initial report structure
            setTeamReports((prev) => ({
                ...prev,
                [team.teamID]: {
                    seasons: {},
                    team,
                },
            }));
        }
    }, [teams]);

    // Get report of active season, if not yet fetched already
    const [teamCount, setTeamCount] = useState(0);
    const [loadingPercentage, setLoadingPercentage] = useState(0);
    const [loading, setLoading] = useState(true);

    const isTeamReportsInitialLoad = useRef(true);

    useEffect(() => {
        const fetchTeamReports = async () => {
            for (const [teamID, seasonID] of Object.entries(activeSeason)) {
                if (!teamReports[teamID]?.seasons?.[seasonID]?.isInitialized) {
                    setTeamReports((prev) => ({
                        ...prev,
                        [teamID]: {
                            ...prev[teamID],
                            seasons: {
                                ...prev[teamID].seasons,
                                [seasonID]: {
                                    ...prev[teamID].seasons[seasonID],
                                    isInitialized: true,
                                    isLoading: true,
                                    isError: false,
                                },
                            },
                        },
                    }));

                    if (seasonID) {
                        try {
                            const reportsResult = await fetchTeamSeasonReport({
                                teamID,
                                seasonID,
                                sessionID: timestampRef,
                                concatenated: true,
                            });

                            if ('error' in reportsResult) {
                                throw new Error(`Failed to download report`);
                            }

                            const reportUrl =
                                reportsResult?.data?.data.objectURL;

                            if (reportUrl) {
                                const response = await fetch(reportUrl);

                                if (!response.ok) {
                                    throw new Error(
                                        `Failed to fetch data from ${reportUrl}`
                                    );
                                }
                                const data = await response.json();

                                setTeamReports((prev) => ({
                                    ...prev,
                                    [teamID]: {
                                        ...prev[teamID],
                                        seasons: {
                                            ...prev[teamID].seasons,
                                            [seasonID]: {
                                                ...prev[teamID].seasons[
                                                    seasonID
                                                ],
                                                events: data,
                                                isLoading: false,
                                            },
                                        },
                                    },
                                }));

                                await delay(500); // Throttle requests
                            }
                        } catch (error) {
                            setTeamReports((prev) => ({
                                ...prev,
                                [teamID]: {
                                    ...prev[teamID],
                                    seasons: {
                                        ...prev[teamID].seasons,
                                        [seasonID]: {
                                            ...prev[teamID].seasons[seasonID],
                                            isLoading: false,
                                            isError: true,
                                        },
                                    },
                                },
                            }));
                        }

                        setTeamCount((prevCount) => prevCount + 1); // Increment count
                    }
                }
            }
        };

        if (
            isTeamReportsInitialLoad.current &&
            Object.keys(activeSeason).length > 0
        ) {
            isTeamReportsInitialLoad.current = false;
            fetchTeamReports();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSeason]);

    // Calculate loading percentage and update loading state
    useEffect(() => {
        if (teams.length > 0) {
            const newLoadingPercentage = Math.round(
                (teamCount / teams.length) * 100
            );
            setLoadingPercentage(newLoadingPercentage);

            if (newLoadingPercentage >= 100) {
                setLoading(false);
            }
        }
    }, [teamCount, teams.length]);

    // Get all participating seasons for each teams
    useEffect(() => {
        const fetchTeamSeasons = async () => {
            for (const team of teams) {
                const { teamID } = team;
                const seasonsResult = await fetchTeamParticipatingSeasons({
                    teamID,
                    cursor: '',
                });

                const seasons = seasonsResult.data?.data;

                if (seasons) {
                    setSeasonData((prev) => ({
                        ...prev,
                        [teamID]: seasons,
                    }));
                }

                await delay(500); // Throttle requests
            }
        };

        if (teams && teams.length > 0) {
            fetchTeamSeasons();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams]);

    const handleSeasonChange = (e: { value: string }, teamID: string) => {
        const { value } = e; // Destructure to get the value from the event object

        setActiveSeason((prev) => ({
            ...prev,
            [teamID]: value,
        }));
    };

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    const rightToolbar = (
        <div className="p-button-group">
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide ENV, INT & FGT Scores
                </label>
                <InputSwitch
                    checked={hideIntFGTScore}
                    onChange={(e) => sethideIntFGTScore(e.value)}
                />
            </div>
            <div
                className="p-button p-button-text p-button-plain"
                style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '5px',
                    border: '1px solid #ced4da',
                    padding: '8px 15px',
                    borderRadius: '2px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                }}
            >
                <label
                    style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#4f4f4f',
                    }}
                >
                    Hide Empty Cols
                </label>
                <InputSwitch
                    checked={hideEmptyCol}
                    onChange={(e) => setHideEmptyCol(e.value)}
                />
            </div>
            <SelectButton
                value={dataType}
                onChange={(e) => setDataType(e.value)}
                options={dataTypeOptions}
            />
        </div>
    );

    const getIntegrityValue = (events: any, stat: StatConfig) => {
        let value = 0;

        events.forEach((event: any) => {
            let flags = event.flags || [];

            // calculate integrity score based on
            flags =
                flags && flags.length > 0
                    ? typeof flags[0] === 'string'
                        ? flags
                        : flags[0]
                    : [];

            const matchedFlags = isArray(flags)
                ? flags.filter((val: string) => stat.flagLabels?.includes(val))
                : [];

            value += 5 - matchedFlags.length;
        });

        return value / events.length;
    };

    const getFairGameTime = (events: any) => {
        let value = 0;
        let count = 0;

        events.forEach((event: any) => {
            const fgtScore = parseFloat(event.fairGameTime);
            if (!isNaN(fgtScore)) {
                value += fgtScore;
            }
            if (!isNaN(fgtScore)) {
                count++;
            }
        });

        // Calculate average fairGameTime
        return value / count;
    };

    const getMinGt = (events: any) => {
        let pBelowTargetMatchScore = 0;
        let totalCount = 0;
        let fgtCount = 0;

        events.forEach((event: any) => {
            if (event.fairGameTime && event.pBelowTarget) {
                const pBelowTarget = event.pBelowTarget;

                if (pBelowTarget.length >= 4) {
                    pBelowTargetMatchScore = 0;
                } else if (pBelowTarget.length >= 3) {
                    pBelowTargetMatchScore = 1;
                } else if (pBelowTarget.length >= 2) {
                    pBelowTargetMatchScore = 2;
                } else if (pBelowTarget.length >= 1) {
                    pBelowTargetMatchScore = 3;
                } else {
                    pBelowTargetMatchScore = 4;
                }

                // Accumulate pBelowTargetMatchScore to totalCount
                totalCount += pBelowTargetMatchScore;
            }

            if (!isNaN(parseFloat(event.fairGameTime))) {
                fgtCount++;
            }
        });

        return totalCount / fgtCount;
    };

    const getDisciplineValue = (events: any) => {
        let intCount = 0;
        let matchDiscScore = 0;

        events.forEach((event: any) => {
            let discCount = 0;
            const discTotal =
                event.disciplineList &&
                event.disciplineList[0] &&
                typeof event.disciplineList[0] === 'string'
                    ? event.disciplineList
                    : [];

            if (isArray(discTotal)) {
                discTotal.forEach(() => {
                    discCount++;
                });
            }

            if (discCount > 1) {
                matchDiscScore = matchDiscScore + 0;
            } else if (discCount === 1) {
                matchDiscScore = matchDiscScore + 1;
            } else {
                matchDiscScore = matchDiscScore + 2;
            }

            const fgtCount = parseFloat(event.integrityScore);
            if (!isNaN(fgtCount)) {
                intCount++;
            }
        });
        return matchDiscScore / (intCount * 2);
    };

    const renderHeaderCell = (stat: StatConfig) => {
        const article = stat.hsArticle;
        const desc = stat.description;

        return (
            <div>
                <Tooltip
                    target={`.cell-${stat.shortName}`}
                    autoHide={false}
                    position="right"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: '#fff' }}>
                            <div>{stat.name}</div>
                            {desc && showInfo && <div>{stat.description}</div>}
                        </div>

                        {desc && (
                            <RookieButton
                                style={{ flex: 'none' }}
                                onClick={() => desc && setShowInfo(!showInfo)}
                                text={true}
                                icon={showInfo ? 'close' : 'info'}
                            />
                        )}

                        {article && (
                            <RookieButton
                                style={{ flex: 'none' }}
                                text={true}
                                data-beacon-article={article}
                                icon="help"
                            />
                        )}
                    </div>
                </Tooltip>
                <span>{stat.shortName}</span>
            </div>
        );
    };

    const renderIntegrityScore = (row: SeasonGameSummary, stat: StatConfig) => {
        let value = getIntegrityValue(row.events, stat);

        const intValue = parseFloat(value.toFixed(1));

        const iconAndTooltip = (name: string, color: string) => (
            <div className={`integrity-score-icon-${row?.team?.teamID}`}>
                <Tooltip
                    target={`.integrity-score-icon-${row?.team?.teamID}`}
                    autoHide={false}
                >
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Average Integrity Score: {intValue}/5
                            <RookieButton
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: '#ff6700',
                                    opacity: '0.9',
                                }}
                                text={true}
                                data-beacon-article={stat.hsArticle}
                                icon="help"
                            />
                        </div>
                    </div>
                </Tooltip>
                <Icon name={name} size="large" style={{ color }} />
            </div>
        );

        switch (true) {
            case intValue === 0:
                return iconAndTooltip('mood_bad', '#CD2335');
            case intValue <= 1:
                return iconAndTooltip('sentiment_dissatisfied', '#AF553A');
            case intValue <= 2:
                return iconAndTooltip('sentiment_neutral', '#9D743C');
            case intValue <= 3:
                return iconAndTooltip('sentiment_satisfied', '#F08036');
            case intValue <= 4:
                return iconAndTooltip('mood', '#EDB024');
            case intValue > 4:
                return iconAndTooltip('add_reaction', '#6FBF42');
            default:
                return null;
        }
    };

    const renderFairGameScore = (row: any) => {
        const value = getFairGameTime(row.events);

        return renderRating(value);
    };

    const renderRating = (value: any) => {
        if (typeof value === 'string') {
            return <span>{value}</span>;
        }

        if (value === undefined || value === null || isNaN(value)) {
            return (
                <Tag
                    style={{
                        width: '40px',
                        color: '#fff',
                        background: '#808080',
                    }}
                >
                    NA
                </Tag>
            );
        }

        let rating = {
            label: '',
            color: '',
        };

        switch (true) {
            case value < 0.5:
                rating = {
                    label: 'Below Average',
                    color: '#CD2335',
                };
                break;
            case value < 0.6:
                rating = {
                    label: 'Average',
                    color: '#EE5145',
                };
                break;
            case value < 0.75:
                rating = {
                    label: 'Good',
                    color: '#F08036',
                };
                break;
            case value < 0.9:
                rating = {
                    label: 'Very Good',
                    color: '#EDB024',
                };
                break;
            case value <= 1:
                rating = {
                    label: 'Excellent',
                    color: '#6FBF42',
                };
                break;
        }

        return (
            <Tag
                style={{
                    width: '40px',
                    color: '#fff',
                    background: rating.color,
                }}
            >
                {Math.round(value * 100)}
            </Tag>
        );
    };

    const renderMatchResult = (row: any) => {
        let wins = 0;
        let losses = 0;
        let draws = 0;

        row.events.forEach((event: any) => {
            wins += parseInt(event.wins, 10) || 0;
            losses += parseInt(event.losses, 10) || 0;
            draws += parseInt(event.draws, 10) || 0;
        });

        return (
            <div>
                W: {wins} | L: {losses} | D: {draws}
            </div>
        );
    };

    const renderTeamScoringAccuracy = (row: any) => {
        let value = 0;
        let count = 0;

        row.events.forEach((event: any) => {
            const accuracy = parseFloat(event.teamScoringAccuracy);

            if (!isNaN(accuracy)) {
                value += accuracy;
            }

            const teamScore = parseFloat(event.teamScore);
            const oppositionScore = parseFloat(event.oppositionScore);
            if (
                !isNaN(teamScore) &&
                !isNaN(oppositionScore) &&
                (teamScore !== 0 || oppositionScore !== 0)
            ) {
                count++;
            }
        });

        return value ? `${Math.round((value * 100) / count)}%` : '-';
    };

    const getEnvironmentScoreWeights = (hasFairGameTime: boolean) => {
        // Apply contextual weightings to ENV score
        // If all FGT values are NA (i.e., no bench available to team)
        if (hasFairGameTime) {
            return {
                integrityScoreWeight: 20,
                fairGameTimeScoreWeight: 20,
                minGtScoreWeight: 45,
                disciplineScoreWeight: 15,
            };
        } else {
            return {
                integrityScoreWeight: 60,
                fairGameTimeScoreWeight: 0,
                minGtScoreWeight: 0,
                disciplineScoreWeight: 40,
            };
        }
    };

    const getEnvironmentData = (row: any, stat: StatConfig) => {
        const integrity = getIntegrityValue(
            row.events,
            config['integrityScore']
        );

        const fairGameTime = getFairGameTime(row.events);
        const minGt = getMinGt(row.events);
        const discipline = getDisciplineValue(row.events);
        const hasFGT = !(isNaN(fairGameTime) || fairGameTime === undefined);
        const weights = getEnvironmentScoreWeights(hasFGT);

        const integrityScore = Math.round(
            weights.integrityScoreWeight * (integrity / 5)
        );
        const fairGameTimeScore = Math.round(
            weights.fairGameTimeScoreWeight * fairGameTime
        );
        const minGtScore = Math.round(weights.minGtScoreWeight * (minGt / 4));
        const disciplineScore = Math.round(
            weights.disciplineScoreWeight * discipline
        );

        let envScore = parseFloat(
            Math.round(
                (integrityScore || 0) +
                    (fairGameTimeScore || 0) +
                    (minGtScore || 0) +
                    (disciplineScore || 0)
            ).toFixed(0)
        );

        return {
            scores: {
                envScore,
                integrityScore,
                fairGameTimeScore,
                minGtScore,
                disciplineScore,
            },
            weights,
        };
    };

    const renderEnvironmentCoeff = (row: any, stat: StatConfig) => {
        const { scores, weights } = getEnvironmentData(row, stat);

        // Render the environment score Tootip
        return (
            <div className={`cell-environmentCoeff-${row?.team?.teamID}`}>
                {renderRating(scores.envScore / 100)}

                <Tooltip
                    target={`.cell-environmentCoeff-${row?.team?.teamID}`}
                    autoHide={false}
                    position="right"
                    onBeforeHide={() => setShowInfo(false)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column', // Ensure items stack vertically
                            alignItems: 'flex-start', // Align items to the start of the column
                        }}
                    >
                        <div>Environment Score Composition:</div>
                        <div>
                            <br />
                        </div>

                        <div>
                            - Minimum Game Time:{' '}
                            {scores.minGtScore
                                ? `${scores.minGtScore} / ${weights.minGtScoreWeight}`
                                : `-/-`}
                        </div>
                        <div>
                            - Fair Game Time:{' '}
                            {scores.fairGameTimeScore
                                ? `${scores.fairGameTimeScore} / ${weights.fairGameTimeScoreWeight}`
                                : `-/-`}
                        </div>
                        <div>
                            - Report Integrity: {scores.integrityScore} /{' '}
                            {weights.integrityScoreWeight}
                        </div>
                        <div>
                            - Disciplinary: {scores.disciplineScore} /{' '}
                            {weights.disciplineScoreWeight}
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    };

    const renderScoreMargin = (row: any) => {
        let teamSc = 0;
        let oppSc = 0;

        row.events.forEach((event: any) => {
            teamSc += parseInt(event.teamScore, 10) || 0;
            oppSc += parseInt(event.oppositionScore, 10) || 0;
        });

        const total = teamSc - oppSc;

        if (dataType === 'Total') {
            return total;
        } else {
            let count = 0;
            row.events.forEach((event: any) => {
                const teamScore = parseFloat(event.teamScore);
                const oppositionScore = parseFloat(event.oppositionScore);
                if (
                    !isNaN(teamScore) &&
                    !isNaN(oppositionScore) &&
                    (teamScore !== 0 || oppositionScore !== 0)
                ) {
                    count++;
                }
            });
            return total !== 0 ? Number((total / count).toFixed(1)) : 0;
        }
    };

    const renderScore = (row: any, val: string) => {
        let total = 0;

        row.events.forEach((event: any) => {
            total += parseInt(event[val], 10) || 0;
        });

        if (dataType === 'Average') {
            let count = 0;

            row.events.forEach((event: any) => {
                const teamScore = parseFloat(event.teamScore);
                const oppositionScore = parseFloat(event.oppositionScore);
                if (
                    !isNaN(teamScore) &&
                    !isNaN(oppositionScore) &&
                    (teamScore !== 0 || oppositionScore !== 0)
                ) {
                    count++;
                }
            });

            return total > 0 && count > 0
                ? Number((total / count).toFixed(1))
                : 0;
        } else {
            return Number(total);
        }
    };

    const renderCount = (row: any, val: string) => {
        let total = 0;

        row.events.forEach((event: any) => {
            total += parseInt(event[val], 10) || 0;
        });

        if (dataType === 'Average') {
            let count = row.events.length;

            return total > 0 && count > 0
                ? Number((total / count).toFixed(1))
                : 0;
        } else {
            return Number(total);
        }
    };

    const tableFooter = (
        <div className="table-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    const dataValue: any[] = useMemo(() => {
        let values = [];
        for (const [teamID, reportData] of Object.entries(teamReports)) {
            const report = reportData.seasons[activeSeason[teamID]];

            values.push({
                team: reportData.team,
                events: report?.events,
                isLoading: report?.isLoading,
                isError: report?.isError,
            });
        }

        return values;
    }, [activeSeason, teamReports]);

    const handleSort = ({ data, order }: ColumnSortEvent, statKey: string) => {
        const stat = config[statKey as keyof typeof config];

        if (order) {
            switch (statKey) {
                case 'matchResult':
                    return orderBy(
                        data,
                        (o) => (o.events ? renderMatchResult(o) : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'gamesPlayed':
                    return orderBy(
                        data,
                        (o) => (o.events ? o.events.length : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'teamScoringAccuracy':
                    return orderBy(
                        data,
                        (o) => (o.events ? renderTeamScoringAccuracy(o) : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'integrityScore':
                    return orderBy(
                        data,
                        (o) =>
                            o.events ? getIntegrityValue(o.events, stat) : 0,
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'fairGameTime':
                    return orderBy(
                        data,
                        (o) => (o.events ? getFairGameTime(o.events) : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'environmentCoeff':
                    return orderBy(
                        data,
                        (o) => {
                            if (o.events) {
                                const envData = getEnvironmentData(o, stat);
                                return envData.scores.envScore;
                            } else return 0;
                        },
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'teamScore':
                case 'oppositionScore':
                    return orderBy(
                        data,
                        (o) => (o.events ? renderScore(o, statKey) : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'rotationCount':
                    return orderBy(
                        data,
                        (o) => (o.events ? renderCount(o, statKey) : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
                case 'scoreMargin':
                    return orderBy(
                        data,
                        (o) => (o.events ? renderScoreMargin(o) : 0),
                        order > 0 ? 'asc' : 'desc'
                    );
            }
        }
    };

    return (
        <PageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader title="Summary Report" />
                <RookieButton
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        opacity: 'inherit',
                        marginLeft: '-10px', // Use a negative margin to reduce space
                        marginTop: '50px',
                    }}
                    text={true}
                    data-beacon-article="672464d01cdd50682627a7a2"
                    icon="help"
                />
            </div>
            <Toolbar end={rightToolbar} />
            {loading ? (
                <ProgressBar
                    value={loadingPercentage}
                    style={{ height: '20px' }}
                />
            ) : (
                <DataTable
                    showGridlines
                    value={dataValue ? dataValue : []}
                    sortField="team.teamName"
                    sortOrder={1}
                    columnResizeMode="expand"
                    resizableColumns
                    stripedRows={true}
                    footer={tableFooter}
                >
                    <Column
                        sortable
                        field="team.teamName"
                        header="TEAM"
                        body={(row) => {
                            if (!row || !row.team) {
                                return <Skeleton />;
                            }

                            const url = `${window.location.pathname
                                .split('/o/')
                                .slice(0, -1)
                                .join('/')}/o/${organisationID}/t/${
                                row.team.teamID
                            }/reports/summary`;

                            return (
                                <a
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        fontWeight: '600',
                                        color: '#000',
                                        textDecoration: 'none',
                                    }}
                                >
                                    {row.team.teamName}
                                </a>
                            );
                        }}
                    />

                    <Column
                        header="Season"
                        body={(row) => {
                            const seasons = seasonData[row.team.teamID];

                            if (!row || !row.team || !seasons) {
                                return <Skeleton />;
                            }

                            return (
                                <Dropdown
                                    style={{ width: '100%' }}
                                    value={activeSeason[row.team.teamID]}
                                    onChange={(e) =>
                                        handleSeasonChange(e, row.team.teamID)
                                    }
                                    className={row.isError ? 'p-invalid' : ''}
                                    valueTemplate={(val) => {
                                        return (
                                            <div className="p-dropdown-value">
                                                {val && val.label}
                                                {row.isError && (
                                                    <Icon name="error" />
                                                )}
                                            </div>
                                        );
                                    }}
                                    tooltip={
                                        row.isError && 'Unable to find report.'
                                    }
                                    options={seasons.map((season) => ({
                                        label: season.seasonName,
                                        value: season.seasonID,
                                    }))}
                                />
                            );
                        }}
                    />

                    {columns.map((val) => {
                        const stat = config[val as keyof typeof config];

                        if (!stat) return null;

                        if (hideIntFGTScore) {
                            if (
                                val === 'integrityScore' ||
                                val === 'fairGameTime' ||
                                val === 'environmentCoeff'
                            ) {
                                return null;
                            }
                        }

                        return (
                            <Column
                                key={stat.shortName}
                                sortable={val !== 'matchResult'}
                                field={val}
                                align="center"
                                alignHeader="center"
                                header={renderHeaderCell(stat)}
                                headerClassName={`cell-${stat.shortName}`}
                                sortFunction={(columnSort) =>
                                    handleSort(columnSort, val)
                                }
                                body={(row) => {
                                    if (row.isError) {
                                        return '-';
                                    }
                                    if (
                                        !row ||
                                        !row.team ||
                                        !row.events ||
                                        row.isLoading
                                    ) {
                                        return (
                                            <Skeleton
                                                width="2rem"
                                                height="1rem"
                                            />
                                        );
                                    }

                                    switch (val) {
                                        case 'matchResult':
                                            return renderMatchResult(row);
                                        case 'gamesPlayed':
                                            return row.events.length;
                                        case 'teamScoringAccuracy':
                                            return renderTeamScoringAccuracy(
                                                row
                                            );
                                        case 'integrityScore':
                                            return renderIntegrityScore(
                                                row,
                                                stat
                                            );
                                        case 'fairGameTime':
                                            return renderFairGameScore(row);
                                        case 'environmentCoeff':
                                            return renderEnvironmentCoeff(
                                                row,
                                                stat
                                            );
                                        case 'teamScore':
                                        case 'oppositionScore':
                                            return renderScore(row, val);
                                        case 'rotationCount':
                                            return renderCount(row, val);
                                        case 'scoreMargin':
                                            return renderScoreMargin(row);
                                    }
                                }}
                            />
                        );
                    })}
                </DataTable>
            )}
        </PageContainer>
    );
};

export default OrgTeamSummaryReport;
